.integrations-header {
  height: auto;
  padding: 0 15px;

  > div {
    text-align: center !important;
  }

  h1 {
    font-size: 30px;
  }

  form {
    margin-bottom: 10px;
  }

  img {
    height: 120px;
  }
}

.integration-categories {
  ul.nav {
    li {
      display: inline-block;
    }
  }
}

#integrations-index {
  .integrations-create-list {
    a.integration-create-item {
      padding: 10px;
      position: relative;
      height: auto;

      .item-img {
        flex: 50px 0 0;
        align-items: center;
      }

      .item-details {
        flex: 1 1 auto;
        padding: 5px;
        line-height: 1em;
        flex-direction: column;
        align-items: flex-start;

        .item-title {
          margin: 0;
          font-size: 18px;
          padding-bottom: 5px;
        }

        .item-description {
          font-size: 15px;
          line-height: 1.1em;
        }
      }
      .item-extra {
        position: absolute;
        right: 3px;
        top: -3px;
        width: auto;
        font-size: 14px;
      }
    }
  }
}
