#video-funnel-playbook-header {
  height: 685px;
  background-image: image-url('funnel-playbook/funnels-landing-page.svg');
  background-color: $blue;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: initial;
  position: absolute;
  left: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 96%, 0 85%);
}

#video-funnel-playbook {
  position: relative;
  h1 {
    font-size: 40px;
    max-width: 400px;
    font-weight: 900;
    color: white;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 36px;
    max-width: 870px;
    margin: auto;
    margin-bottom: 65px;
    font-weight: 700;
    letter-spacing: -0.87px;
    color: #000000;
    font-family: Lato;
  }
  h3 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.36px;
    color: #403f3f;
    margin-bottom: 20px;
  }
  h4,
  h5 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.24px;
    color: #403f3f;
    margin-bottom: 20px;
  }

  .btn-read-now {
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #323232;
  }

  .funnel-header {
    color: white;
    max-width: 455px;
    height: 685px;
    position: relative;
    margin-bottom: 65px;
    .subtitle {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.48px;
      color: #ffffff;
      margin-bottom: 30px;
    }
    &-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-bottom: 100px;
    }
  }

  .funnel-body {
    .read-now-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 125px;
      .read-now-image {
        img {
          width: auto;
          max-width: 100%;
          max-height: 380px;
        }
      }
      .read-now-content {
        padding: 0 15px;
        ul {
          font-size: 17px;
          line-height: 1.33;
          letter-spacing: 0.1px;
          color: #888888;
          max-width: 400px;
          padding-left: 30px;
          li {
            padding-bottom: 20px;
          }
        }
      }
    }
    .proof-content {
      &-description {
        max-width: 780px;
        margin: auto;
        margin-bottom: 70px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.48px;
        text-align: center;
        color: #666666;
      }
      .testimonial-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 60px;
        .testimonial-entry {
          height: 235px;
          width: 460px;
          &-body {
            font-family: Lato;
            font-size: 18px;
            line-height: 1.17;
            letter-spacing: 0.1px;
            color: #878787;
            margin-bottom: 15px;
          }
          &-author {
            display: flex;
            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }
            .name {
              font-size: 18px;
              letter-spacing: 0.1px;
              color: #878787;
              height: fit-content;
              margin: auto 20px;
            }
          }
        }
        .testimonial-read-now {
          @extend .testimonial-entry;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
  }
}

.button-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 425px) {
  #video-funnel-playbook-header {
    background-image: none;
    height: 460px;
  }

  #video-funnel-playbook {
    padding: 0 35px;
    h1 {
      font-size: 8vw;
      line-height: 1.33;
    }
    h2,
    h3 {
      font-size: 6.5vw;
      margin-bottom: 30px;
    }
    h5 {
      font-size: 16px;
    }

    .funnel-header {
      height: 460px;
      &-container {
        .subtitle {
          font-size: 5vw;
        }
      }
    }
    .funnel-body {
      .read-now-section {
        display: inline-block;
        margin-bottom: 50px;
        .read-now-image {
          margin-bottom: 30px;
        }
        .read-now-content {
          padding: unset;
          ul {
            font-size: 14px;
            padding-left: 16px;
            li {
              padding-bottom: 20px;
            }
          }
        }
      }
      .proof-content {
        &-description {
          font-size: 4.5vw;
        }
        .testimonial-list {
          padding: unset;
          .testimonial-entry {
            margin-bottom: 30px;
            height: unset;
          }
        }
      }
    }

    .btn-read-now {
      width: 100%;
    }

    .button-area {
      justify-content: center;
      a,
      button {
        width: 250px;
      }
      img {
        width: 250px !important;
      }
      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
