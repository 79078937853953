@import '../partials/fontawesome';

#pricing-header {
  height: 950px;
  background-image: linear-gradient(178deg, $blue 70%, #fff calc(70% + 1px));
  position: absolute;
  left: 0;
  right: 0;
}

#pricing-modal {
  #pricing-header {
    background-image: linear-gradient(
      177deg,
      #27b3c1 70%,
      #fff calc(70% + 1px)
    );
    margin-top: -41px;
    border-radius: 5px;
  }
  #pricing-header-text {
    text-align: center;
    color: white;
    padding-top: 15px;
    h1 {
      max-width: 900px;
      margin: 10px auto 20px;
    }
  }

  .pricing-plan-list {
    max-width: 900px;
    margin: auto;
  }

  .plan-item-cta {
    margin: unset;
  }

  .pricing-plan-card-list {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pricing-plan-item {
    height: fit-content;
  }
}

#pricing-header-text {
  color: white;
  text-align: center;
  padding-top: 45px;

  h1 {
    font-size: 40px;
    font-family: 'Circular Std', sans-serif;
    font-weight: bold;
    color: white;
  }

  .subtitle {
    font-size: 24px;
  }

  #pricing-toggle {
    font-weight: 900;
    letter-spacing: 0.22px;
    a {
      line-height: 40px;
      color: unset;
    }
    li {
      vertical-align: top;
      color: white;
      letter-spacing: 0.22px;
      font-size: 18px;
      span {
        font-weight: 500;
        font-style: italic;
      }
      a {
        display: block;
        width: 105px;
        padding: 0 15px;
        border-bottom: 2px solid $tealish-two;
        font-weight: bold;
        &:hover {
          border-bottom: 2px solid $grey;
          cursor: pointer;
        }
      }
      &.active > a {
        color: white;
        background-color: #60ccd7;
        border-radius: 22px;
        font-weight: 900 !important;
      }
    }
  }
}

#pricing-plans {
  position: relative;

  .pricing-plan-base {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  .pricing-plan-list {
    @extend .pricing-plan-base;
    margin: 15px auto 70px;
    position: relative;
    .disclaimer {
      position: absolute;
      bottom: -45px;
      text-align: end;
      right: 0;
      font-family: Lato;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.08px;
      color: $brown-grey-two;
    }
  }

  .plans-contactus {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 186px;
      height: 109px;
    }
    &-text {
      font-family: Lato;
      line-height: 1.89;
      letter-spacing: 0.22px;
      color: #403f3f;
      font-stretch: normal;
      padding-left: 22px;

      font-size: 27px;
      font-weight: bold;
      font-style: italic;
      letter-spacing: 0.32px;
      p {
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.22px;
      }
      a {
        text-decoration: underline;
        color: #403f3f;
      }
    }
    .btn {
      color: white;
      font-size: 14px;
      font-style: normal;
      text-decoration: none;
    }
  }
}

.pricing-plan-item {
  height: 100%;
  width: 256px;
  background: white;
  @include box-shadow(0 4px 8px rgba(#42332a, 0.25));
  @include border-radius(8px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin-right: 35px;
  &.last {
    margin-right: 0;
  }

  .plan-item-header {
    height: 155px;
    background: white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    img.plan-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 40px 50px 10px;
    }
    img.best-value {
      position: absolute;
      top: -1px;
      right: 0px;
      object-fit: contain;
    }
  }

  .plan-item-sub-info {
    .warning-15 {
      color: $tealish-two;
      line-height: 1em;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .plan-body {
    padding: 0 15px;
  }

  .plan-item-price {
    margin: 0 5px 15px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 1em;
    font-family: 'Circular Std', sans-serif;
  }

  .plan-item-recurrence {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    margin-top: 5px;
    margin-bottom: 15px;

    &.recurrence-annual,
    &.recurrence-monthly {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .plan-price {
    font-weight: 900;
  }

  .plan-price-notes {
    font-size: 16px;
    letter-spacing: 0.19px;
    text-align: center;
    color: #42332a;
    height: 70px;
  }

  .plan-item-sub-info {
    color: $tealish-two;
    font-style: italic;
    font-weight: normal;
    margin-top: 5px;
  }

  .plan-item-sub-note {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0.17px;
  }

  .plan-item-popular-badge {
    padding: 4px 10px;
    margin-bottom: 10px;
    display: inline-block;

    background: #27c17e;
    @include border-radius(4px);
    color: white;
    font-weight: bold;
    font-size: 17px;
    text-transform: uppercase;
  }

  .plan-item-cta {
    margin-bottom: 10px;
    padding: 0 15px;
    text-align: center;
    a {
      height: 50px;
      line-height: 50px;
      padding: 0;
      font-family: Lato;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.19px;
      text-align: center;
      color: #42332a;
      &:hover {
        color: black !important;
        background-color: $sunflower-yellow;
        border-color: $sunflower-yellow;
      }
    }
  }

  .plan-currency-info {
    color: #777777;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 10px;
  }
}

.pricing-plan-item {
  ul.plan-item-feature-list {
    font-size: 18px;
    line-height: 1.7em;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 20px;

    li {
      font-family: Lato !important;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.22px;
      color: #6d7278;

      padding: 20px 0;
      width: 100%;
      min-height: 55px;
      display: table;

      .feature-empty {
        width: 11px;
        height: 1px;
        border: solid 1px #979797;
        margin: auto;
        display: block;
      }
      .feature-link {
        text-decoration: underline;
        &.feature-text-anchor {
          text-decoration: none;
          color: #6d7278;
          font-weight: normal;
          border-bottom: 1.5px dotted #a6a6a6;
          padding-bottom: 1px;
        }
      }

      div {
        display: table-cell;
        vertical-align: middle;
      }
      span {
        font-size: 16px;
        &.new {
          border-radius: 12.5px;
          background-color: $rosy-pink;
          color: white;
          font-size: 14px;
          font-weight: bold;
          padding: 2px 8px;
        }
      }
      &::before {
        border-top: thin solid $border-light-grey;
        content: '';
        width: 80%;
        margin: auto;
        display: table-caption;
      }
    }
  }
}

.bonjoro-15 {
  margin-top: 50px;
  margin-bottom: 25px;
}

#pricing-entrepreneur-offer {
  padding: 60px 0 0;

  img#bear-entrepreneur {
    max-width: 300px;
  }

  .offer-description {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#pricing-button-wall {
  overflow-x: hidden;
  position: relative;
  padding-top: 50px;

  .button-wall-title {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    text-align: center;

    h3 {
      font-family: 'Circular Std', sans-serif;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      color: #777;
    }
  }

  .pricing-button-container {
    height: 400px;
    display: flex;
    flex-direction: row;
    transform: translateX(-50%);

    .button-col {
      height: 100%;
      width: 33.33%;
      min-width: 190px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 5px;

      button.btn {
        display: block;
        padding: 12px 0;
        margin-bottom: 10px;
        font-weight: normal;
      }
    }
  }
}

#pricing-breakdown-table {
  tbody {
    tr {
      td {
        > a {
          color: #444;
          text-decoration: none;

          i {
            color: $blue;
          }
        }
      }
    }
  }
}

#pricing-breakdown-title {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;
  h3 {
    text-indent: 4px;
    //font-size: 30px;
    font-size: 30px;
    line-height: 2em;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
}

.popover {
  max-width: 280px;
}

#pricing-faq {
  max-width: 800px;
  margin: 0 auto;

  h3 {
    font-size: 30px;
    line-height: 2em;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  h4 {
    font-size: 22px;
    font-weight: bold;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}

.feature-group {
  .feature-group-heading {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 50px;

    &:after {
      content: ''; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      width: 75px; /* Change this to whatever width you want. */
      padding-top: 10px; /* This creates some space between the element and the border. */
    }

    &.orange:after {
      border-bottom: 5px solid $bright-orange;
    }

    &.yellow:after {
      border-bottom: 5px solid $sunflower-yellow;
    }

    &.teal:after {
      border-bottom: 5px solid $tealish-two;
    }
  }

  .feature-card {
    height: 100px;
    margin-bottom: 50px;
    display: flex;

    .feature-card-icon {
      margin-right: 20px;
    }

    .feature-card-details {
      .feature-card-heading {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 900;
      }
      .feature-card-body {
      }
    }
  }
  .more-features-coming {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.14px;
    color: #323232;
  }
}
