html#split-register {
  .layout-header {
    display: none;
  }

  .content-left {
    padding-top: 15px;

    .split-form {
      &.form-email-open {
        form#register-form {
          display: block;
        }
        .form-signup-buttons {
          display: none;
        }
      }

      .line-break {
        margin: 20px 0;
      }

      form#register-form {
        display: none;
      }

      .form-title {
        margin-bottom: 15px;

        h1 {
          font-size: 26px;
          margin: 0;
        }
        p.text-lead {
          display: none;
        }
      }

      .password-warning {
        display: none;
      }
    }

    .form-signup-buttons {
      #btn-signup-email {
        display: block;
      }
    }
  }
}
