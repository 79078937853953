$form-width: 385px;
$form-padding: 150px;
$color-step-1: #4b9aff;

html#split-login,
html#split-apps,
html#split-profile,
html#split-register {
  height: 100%;

  body {
    background: white;
  }
}

@media (min-width: 768px) {
  html#split-login .content-right {
    background: image-url('team/img_2262.jpg');
  }

  html#split-login,
  html#split-apps,
  html#split-profile,
  html#split-register {
    .content-left {
      width: 35%;
      min-width: 450px;
    }
    .content-right {
      flex-grow: 1;
      background-position-x: right;
      background-size: cover;
    }
  }
}

.skip-button {
  position: absolute;
  bottom: 20px;
  right: 20px;

  a {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
}

html#split-login {
}

html#split-register {
  #btn-signup-email {
    display: none;
  }
}

html#split-profile {
  .col-left {
    padding-right: 7px;
  }
  .col-right {
    padding-left: 7px;
  }

  form.profile-form {
    #profile-img-form {
      display: flex;
      flex-direction: row;
      align-items: center;

      #profile-img-picture {
        border-radius: 4px;
        overflow: hidden;
        width: 35px;

        .holder {
          width: 35px;
          height: 35px;
          background-size: 35px auto;
        }
      }

      #profile-img-btn {
        margin-left: 6px;
      }

      &.well {
        height: 45px;
        padding: 8px 12px;
        background: #f5f5f5;
        border: none;
      }
    }
  }
}

html#split-reset {
  .content-right {
    background: $blue-dark;
  }
}

#layout-split {
  .content-left {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: 100%;
    overflow: hidden auto;
    .content-area {
      padding: 5px;
    }

    .layout-header {
      width: 385px;
      .logo {
        display: inline-block;
        padding: 15px 0;

        @media (max-height: 700px) {
          display: none;
        }
      }
    }
  }

  .country-list {
    z-index: 15;
  }

  @media (max-width: 950px) {
    .content-right {
      display: none;
    }
    .content-left {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 70px 15px 10px 15px;
      flex-direction: row;

      .layout-header {
        display: none;
      }
    }
  }

  @media (max-height: 650px) {
    .content-right {
      .split-right-text {
        display: none;
      }
    }
  }

  @media (min-width: 950px) {
    display: flex;
    flex-direction: row;

    .content-left,
    .content-right {
      width: 50%;
      display: flex;
    }

    .content-left {
      align-items: center;
      justify-content: center;
    }

    .content-right {
      position: relative;
      align-items: center;
      justify-content: center;

      .split-right-text {
        width: 330px;
        position: absolute;
        top: 30px;
        right: 30px;
        background: rgba(black, 0.5);
        padding: 5px 15px;
        border-radius: 5px;
        font-weight: bold;

        h4,
        p {
          color: white;
        }

        h4 {
          font-size: 23px;
        }
        p {
          font-size: 14px;
          a {
            color: #eee;
          }
        }
      }
    }
  }

  @media (min-width: 1250px) {
    .content-left {
      justify-content: center;
    }

    .content-right {
      justify-content: flex-start;
    }
  }
}

.no-flexbox {
  #layout-split {
    display: table;
    min-width: 100%;

    .layout-header {
      position: absolute;
      top: 0;
    }

    .content {
      &-left,
      &-right {
        display: table-cell;
        vertical-align: middle;
      }

      &-left {
        padding-right: 0;

        > * {
          margin-right: 150px;
          margin-left: auto;
        }
      }
    }
  }
}

.form-title {
  margin-bottom: 30px;

  h1 {
    font-size: 40px;
    color: #444;
    font-weight: bolder;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 15px;
  }

  .text-lead {
    color: #444;
    font-size: 18px;
  }
}

.split-form {
  max-width: $form-width;
}

.btn {
  &.btn-action {
    background: $yellow;
    @include border-radius(4px);
    overflow: hidden;
    position: relative;
    color: $brown-dark !important;
    &-secondary {
      color: $tealish-two !important;
      background: white;
      border: 1px solid $tealish-two;
    }
  }
}
