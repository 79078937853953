html#one-col-centered {
  body {
    background: #fdfbfb;
  }

  #app-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .container.centered {
      height: 100%;

      .content-main {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
