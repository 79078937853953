@import '~compass-mixins/lib/compass/css3';
@import '../mixins/no-flexbox';
@import '../theme/colours';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/labels';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import '../../../../resources/assets/sass/partials/icons';

.blog-topic-container {
  ul.blog-topic-list {
    max-width: 800px;
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    overflow: hidden;
    list-style: none;
    padding: 10px 0;
    justify-content: center;

    li {
      flex: 0 0 auto;
      &.active {
        a {
          color: #333;
        }
      }

      a {
        padding: 0 15px;
        color: #999;
        text-transform: uppercase;
        font-size: 13px;
        display: inline-block;
        font-weight: bold;

        &:hover,
        &:active {
        }
      }
    }
  }
}

.blog-subscription-container {
  .create-subscriber-button {
    margin: 0 auto;
    height: 40px;
    width: 400px;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 1em;
    color: $blue;
    background-color: white;
    border: 1px solid $blue;
    @include border-radius(4px);
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
    &.disabled {
      border: 1px solid #ddd;
      background: #eee;
      .create-subscriber-title {
        color: #ddd;
        &:hover {
          cursor: pointer;
          background-color: #eee;
          color: #bbb;
        }
      }
      .create-subscriber-input {
        input {
          color: #ccc;
        }
      }
    }

    .create-subscriber-title {
      flex: 0 0 auto;
      padding: 6px 12px;
      line-height: 26px;
      text-align: right;
      text-transform: uppercase;
      font-size: 14px;
      background: white;
      outline: none;
      border: none;
      @include transition(all 0.2s ease-in);
      &:hover {
        cursor: pointer;
        background-color: $blue;
        color: white;
      }
    }
    .create-subscriber-input {
      flex: 1 1 auto;

      input {
        width: 100%;
        background: none;
        display: block;
        outline: none;
        border: none;
        padding: 0 5px 0 12px;
        height: 36px;
        line-height: 16px;
        &:disabled {
          &::placeholder {
            color: #ccc;
          }
        }
        &::placeholder {
          color: rgba($blue, 0.5);
          font-weight: normal;
        }
      }
    }
  }
}

.blog-index {
  ul.blog-posts-list {
    position: relative;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li.post-container {
      position: relative;
      padding: 15px;
      flex: 0 0 50%;
      list-style: none;
      clear: both;
      margin-bottom: 15px;
      float: left;
      z-index: 1;
    }

    .post-item {
      display: block;
      text-decoration: none;
      border-bottom: 1px dotted transparent;
      padding-bottom: 5px;

      &:hover {
        border-color: #ccc;
      }

      &:active,
      &:hover {
        text-decoration: none;
      }

      .post-published-date {
        font-size: 18px;
        color: $blue;
        padding: 15px 0;
        font-weight: bold;
      }

      .post-header-image {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 250px;
        background: url(https://bonjoro.cdn.prismic.io/bonjoro/60ff784…_matt-wand.png);
        background-position: center;
        background-size: cover;
      }

      .post-item-content {
        padding: 0 10px 10px;

        h3.post-title {
          font-size: 1.5em;
          font-weight: bold;
          line-height: 1.4em;
          color: #444;
        }

        .post-preview {
          color: $grey-dark;
          font-size: 1.4em;
          line-height: 1.4em;
          opacity: 0.8;
        }
      }
    }

    > :first-child {
      margin-top: 0;
    }
  }
}

.blog-post-container {
  .main {
    width: 950px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;

    .header-image {
      img {
        max-width: 100%;
      }
    }

    h1.title {
      font-weight: bold;
      text-align: center;
      margin: 35px 0 5px;
    }

    .author-info-header {
      text-align: center;
      padding: 10px 0 0;

      a,
      span {
        font-size: 1.2em;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 5px;
      }
    }

    .author-info-footer {
      text-align: center;
      h5 {
        color: #888;
        font-size: 0.9em;
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        margin-bottom: 15px;
      }

      p {
        font-size: 1.2em;
        line-height: 1.5em;
        color: #444;
      }
    }

    .image {
      margin-top: 15px;
    }

    .post-content-inner {
      max-width: 700px;
      word-wrap: break-word;
      margin: 45px auto;

      .table-of-contents {
        width: auto;
        margin-bottom: 25px;
        padding: 15px 25px;
        display: inline-block;
        border: 1px solid #ddd;
        background: #fafafa;
        border-radius: 4px;

        h3 {
          margin-top: 0;
        }

        ul {
          list-style: none;
          margin: 0;
          margin-bottom: 0;
          padding: 0;
          li {
          }
        }
      }

      iframe.bonjoro-embed {
        max-width: 30% !important;
      }

      [data-oembed-type='video'] {
        iframe {
          width: 100%;
          height: 400px;
        }
      }

      h2.linkable {
        display: block;
        content: ' ';
        margin-top: -185px;
        height: 185px;
        visibility: hidden;
        pointer-events: none;
      }

      blockquote {
        margin: 0.25em 0;
        padding: 0.35em 40px;
        border-left: none;
        position: relative;
        font-family: Georgia, serif;
        font-size: 18px;
        font-style: italic;
        line-height: 1.45;

        p {
          font-size: 1.5em;
        }

        > h2,
        h3,
        h4,
        h5 {
          line-height: 1.5em;
        }

        &:before {
          display: block;
          padding-left: 10px;
          content: '\201C';
          font-size: 80px;
          position: absolute;
          left: -20px;
          top: -20px;
          color: #7a7a7a;
        }

        cite {
          color: #999999;
          font-size: 14px;
          display: block;
          margin-top: 5px;
          &:before {
            content: '\2014 \2009';
          }
        }
      }

      .block-img {
        text-align: center;

        img {
          border: 1px solid #ddd;
          padding: 5px;
          border-radius: 2px;
          max-width: 100%;
          height: auto;
        }
      }

      p,
      ul,
      ol {
        margin-bottom: 25px;
        font-family: Georgia, 'Times New Roman', serif;
        -webkit-font-smoothing: antialiased;
        line-height: 1.55em;
        font-size: 1.5em;
        color: #365257;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        font-weight: bold;
        font-family: 'Lato', Arial, sans-serif;
      }
    }
  }

  .blog-breadcrumbs {
    color: #999;
    margin: 10px 0 25px;

    .breadcrumb-item {
      margin: 0 auto;
    }

    i {
      margin-left: 10px;
      margin-right: 8px;
    }

    a {
      color: #888;
      font-size: 13px;
      display: inline-block;
      font-weight: bold;
    }
  }

  .author-info {
    //font-family: $font-family;
  }

  .share {
    font-size: 1.5em;

    &.share-icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      align-items: flex-end;

      .share-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: block;
        border: 1px solid #eee;
        margin-top: -1px;
      }

      i.fa {
        cursor: pointer;
        display: inline-block;
      }
    }

    h3 {
      font-size: 1em;
      font-weight: bold;
      color: $grey-dark;
    }
  }

  .subscribe {
    display: flex;
    align-items: center;

    padding: 25px 10px;
    background-color: $grey-light;

    .body {
      padding: 0;
      width: 100%;
    }

    .logo img {
      padding: 0;
      margin: 0 10px;
      height: 70px;
    }

    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.blog,
.post {
  .author {
    font-size: 17px;
  }

  .sidebar {
    &-header {
      font-size: 1.8em;
      font-weight: bold;

      &-large {
        font-size: 1.9em;
      }

      &-small {
        font-size: 1.4em;
      }
    }

    &-download {
      display: flex;

      &-image {
        height: 100px;
        margin-right: 15px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
      }
    }

    & > * {
      margin-bottom: 35px;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    &-topics {
      ul {
        margin-top: 15px;
        padding-left: 0;

        li {
          display: inline-block;
          margin: 5px 5px 5px 0;
        }
      }
    }
  }
}

.label {
  &-keyword {
    @include label-variant($link-yellow);
    @include border-radius(30px);
    color: $grey-dark;
    font-size: 1em;
  }
}

.share,
.post-share {
  .fa-facebook {
    color: $facebook-blue;
  }

  .fa-twitter {
    color: $twitter-blue;
  }

  .fa-linkedin {
    color: $linkedin-blue;
  }

  .fa-google-plus {
    color: $google-plus-red;
  }
}

.blog-post-container,
.blog-index {
  h1,
  .h1 {
    font-size: 36px !important;
  }

  h2,
  .h2 {
    font-size: 30px !important;
  }

  h3,
  .h3 {
    font-size: 24px !important;
  }

  h4,
  .h4 {
    font-size: 18px !important;
  }

  h5,
  .h5 {
    font-size: 14px !important;
  }

  h6,
  .h6 {
    font-size: 12px !important;
  }
}

.articles-container {
  --container-padding-horizontal: 1.75rem;
  @media (min-width: 640px) {
    --container-max-width: 640px;
    max-width: calc(
      var(--container-max-width) - var(--container-padding-horizontal)
    );
  }

  @media (min-width: 768px) {
    --container-max-width: 768px;
  }

  @media (min-width: 1024px) {
    --container-max-width: 1024px;
  }

  @media (min-width: 1164px) {
    --container-max-width: 1164px;
  }

  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding-horizontal);
  padding-right: var(--container-padding-horizontal);
}

.similar-articles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0 4rem;

  .single-article {
    @media (min-width: 640px) {
      width: calc(33.333333% - (16px / 2));
    }

    .article-container {
      border-radius: 0.375rem;
      background-color: #fdfbfb;
      overflow: hidden;
      @media (min-width: 1024px) {
        &:hover {
          box-shadow: 4px 5px 23px 3px rgba(203, 203, 203, 0.5);
        }
      }

      img {
        height: auto;
        width: auto;
        max-width: 100%;
      }

      .article-tag {
        color: #a6a6a6;
        margin-top: 0.75rem;
        text-align: center;
        letter-spacing: 0.05em;
        line-height: 1.875;
        color: #a6a6a6;
        @media (min-width: 1024px) {
          line-height: 1.875;
          letter-spacing: 0.05em;
          font-size: 18px;
        }
      }

      .article-title {
        font-size: 16px;
        line-height: 1.375;
        letter-spacing: -0.39px;
        margin: 0 16px 16px;
        color: #403f3f;
        text-align: center;
        font-family: 'Lato';
        font-weight: 400;
        @media (min-width: 1024px) {
          line-height: 1.875;
          letter-spacing: 0.05em;
          font-size: 18px;
        }
      }
    }
  }
}
