@import '~compass-mixins/lib/compass/css3/box-shadow';

.navbar.navbar-default {
  height: 80px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: white !important;
  width: 100%;
  transform: translateZ(150px);
  padding-top: 21px;
  padding-bottom: 20px;

  .navbar-brand {
    height: 80px;
    padding: 22px;
    margin-right: 30px;

    .brand-container {
      padding: 0;
      margin: 0;
      height: 35px;

      img {
        height: 35px;
      }
    }
  }

  .nav.navbar-nav {
    .menu-icon {
      position: absolute;
      left: -5px;
      top: 25%;
    }

    > li > div.nav-splitter {
      width: 1px;
      background: #ccc;
      height: 40px;
      margin-right: 5px;
    }

    > li > a {
      height: 40px;
      color: $brown-dark;
      font-size: 16px;
      padding: 0 11.8px;
      line-height: 44px;
      font-weight: 700;
      letter-spacing: 0.4px;
      i {
        font-size: 14px;
        margin-left: 8px;
      }
      @include border-radius(5px);

      &.btn.btn-action:hover {
        background: #f0c300 !important;
        color: #42332a !important;
      }

      &:hover {
        color: #1f96a2 !important;
      }
    }
    > li:last-child {
      a {
        margin-right: 0;
      }
    }

    > li > .dropdown-menu {
      border: 0;
    }

    > li > #nav_register_button {
      font-size: 20px;
      padding: 8px 20px;
      display: inline-block;
      height: unset;
      line-height: unset;
      margin-left: 13px;
      letter-spacing: 0.24px;
    }

    a.navbar-btn {
      &.btn-bold {
        background: $red;
        font-weight: bold;
        color: white;
      }

      min-width: 120px;
      width: auto;
      margin: 0 5px 0 5px;
      padding: 0 10px;
      font-size: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .nav.navbar-nav {
    margin: 10px 0;
  }

  .navbar.navbar-default {
    z-index: 1000;
    height: 60px;

    .navbar-collapse {
      position: relative;
      background: white;
      z-index: 15;
      border-color: transparent;
      @include box-shadow(0 3px 6px #ccc);
    }

    .navbar-brand {
      height: 60px;
      padding: 15px;
    }

    .nav.navbar-nav {
      height: auto;
      padding: 0 10px;
      line-height: 40px;
      display: block;

      li {
        background: #fafafa;
        margin-bottom: 3px;
        @include border-radius(4px);
        > .nav-splitter {
          display: none;
        }
        .menu-icon {
          left: 20px;
        }
      }
    }

    .nav.navbar-nav a.navbar-btn {
      min-width: 120px;
      width: 100%;
      margin: 0;
      padding: 0 20px;
      line-height: 40px;
      background: none;
      font-size: 20px;
      text-align: left;
    }
  }
}

@media (max-width: 1024px) {
  ul.nav.navbar-nav {
    &.navbar-right {
      display: inline;
    }

    display: inline-block;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
    height: 26px;
    width: 26px;
    padding: 0;
    margin: 0;
    border-color: transparent !important;

    &:focus,
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .icon-bar {
      width: 100%;
      border-radius: unset;
      background-color: black !important;
      margin: 0 0%;
      margin-bottom: 10px;
    }
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    background-color: white;
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 0 50px !important;
  }
  .collapse.in {
    display: block !important;
  }
  .navbar-container {
    display: block;

    .container {
      margin-left: 0 !important;
      margin-top: 7px;
      width: 100%;
      padding: unset;
    }
  }
  .navbar-head,
  .navbar-nav {
    --container-padding-horizontal: 28px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: var(--container-padding-horizontal) !important;
    padding-right: var(--container-padding-horizontal) !important;

    @media (min-width: 640px) {
      --container-max-width: 640px;
      max-width: calc(
        var(--container-max-width) - var(--container-padding-horizontal)
      );
    }

    @media (min-width: 768px) {
      --container-max-width: 768px;
    }

    @media (min-width: 1024px) {
      --container-max-width: 1024px;
    }
  }

  .navbar-nav > li > a {
    font-size: 18px !important;
    padding: 16px !important;
    border-radius: 6px;
    background-color: #f2f2f2;
    height: initial !important;
    line-height: 27px !important;
    letter-spacing: 0 !important;
  }

  .navbar-nav .open .dropdown-menu {
    padding: unset !important;
    > li {
      padding: unset !important;

      &:not(:first-of-type) {
        margin-top: 16px;
      }

      > a {
        font-size: 16px;
        padding: 2px 16px;
      }
    }
  }

  .navbar-left {
    margin-top: 23px !important;

    > li:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .navbar-right {
    > li:nth-of-type(2) {
      a {
        margin-left: 0 !important;
        margin-top: 16px !important;
      }
    }
    > li > a {
      display: flex !important;
    }
  }
}

@media (min-width: 1025px) {
  .navbar-container {
    display: flex;
    align-items: center;
    --container-padding-horizontal: 28px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--container-padding-horizontal);
    padding-right: var(--container-padding-horizontal);

    @media (min-width: 640px) {
      --container-max-width: 640px;
      max-width: calc(
        var(--container-max-width) - var(--container-padding-horizontal)
      );
    }

    @media (min-width: 768px) {
      --container-max-width: 768px;
    }

    @media (min-width: 1024px) {
      --container-max-width: 1024px;
    }

    @media (min-width: 1164px) {
      --container-max-width: 1164px;
    }
  }
}

.navbar-container {
  .container {
    margin-left: 5px;
    width: 100%;
    padding: unset;
  }

  .brand-container {
    height: unset;
    padding: 0;
    line-height: unset;
    .brand-bear {
      height: 32px;
      margin-right: 0;
    }
  }
}

.navbar-desktop-links {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 32px;
  }
}

.navbar-toggle[aria-expanded='true'] {
  span:nth-of-type(3) {
    width: 70%;
    margin: 0px 12.5% 10px;
  }
}
