h1.title-big,
h2.subtitle-big {
  text-align: center;
  font-weight: bolder;
  font-family: 'Circular Std';
  color: $brown-dark;
}

h1.title-big {
  margin-bottom: 25px;
  font-size: 50px;
  text-transform: uppercase;
}

h2.subtitle-big {
}

.video-player-modal {
  .video-player-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);

    overflow: hidden;
  }
}

.noscroll {
  overflow: hidden;
  margin-right: 17px;
}
