#pricing-header {
  .subtitle {
    font-size: 16px;
    font-weight: bold;
  }
}
#pricing-plans {
  .pricing-plan-list {
    flex-direction: column;
  }
}

.pricing-plan-item {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 0;

  &.plan-item-popular {
    width: 100%;
    z-index: 15;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 0;

    ul.plan-item-feature-list {
      margin-left: 0;
    }
  }

  ul.plan-item-feature-list {
    margin-left: 0;
  }
}

#pricing-entrepreneur-offer {
  display: none;
}

#pricing-button-wall {
  display: none;
}

.pricing-plan-list {
  .plans-contactus {
    position: relative !important;
    order: 6;
  }
  .disclaimer {
    position: relative !important;
    order: 5;
    bottom: unset !important;
    margin-bottom: 20px;
  }
}

.testimonial-customer {
  .testimonial-color-splash.splash-bottom {
    height: 870px !important;
  }
}

@media (max-width: 480px) {
  .testimonial-customer {
    .testimonial-quote {
      font-size: 5.6vw !important;
    }
    .testimonial-author-name {
      font-size: 5vw !important;
    }
    .testimonial-color-splash.splash-bottom {
      height: 210vw !important;
    }
    .testimonial-author-image {
      height: 57vw !important;
    }
  }
}
