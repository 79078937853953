html#features {
  $brown: #544238;

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 20px;
  }

  h1,
  h2 {
    font-weight: 900;
  }

  #feature-header-splash {
    height: 950px;
    background-image: linear-gradient(
      182deg,
      $sunflower-yellow 70%,
      #fff calc(70% + 1px)
    );
    position: absolute;
    left: 0;
    right: 0;
  }

  .feature-hero {
    height: 700px;
    display: flex;
    align-items: center;

    .feature-hero-content {
      position: relative;

      h1 {
        font-size: 50px;
        margin-top: 0;
        margin-bottom: 15px;
        color: #403f3f;
      }

      p.lead {
        font-size: 20px;
        margin-bottom: 30px;
      }

      .testimonial-info {
        font-size: 18px;
      }

      .btn-lg {
        font-size: 20px;
      }

      .feature-actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          margin-right: 20px;
        }
      }
    }

    .feature-testimonial {
      text-align: center;
      cursor: pointer;
      color: #403f3f;

      .feature-testimonial-video {
        .feature-play-button {
          position: absolute;
          left: 45%;
          top: 35%;
          height: 75px;
        }
      }

      .feature-testimonial-footer {
        width: 354px;
        margin: auto;

        .feature-testimonial-footer-content {
          margin-top: 15px;
          display: flex;
          align-items: center;

          .testimonial-avatar-img {
            margin-right: 10px;

            img {
              border-radius: 35px;
            }
          }
        }
      }
    }
  }

  .feature-row {
    margin-top: 75px;
    margin-bottom: 75px;

    h2 {
      i.fa {
        display: none;
        width: 50px;
        height: 50px;
        margin-right: 8px;
        background: $yellow;
        color: white;
        line-height: 50px;
        font-size: 28px;
        text-align: center;
        @include border-radius(50px);
      }
    }

    p {
      margin-bottom: 25px;
    }

    p,
    ul li {
      font-size: 18px;
      color: $brown;
    }

    ul {
      margin-left: 20px;

      li {
        text-indent: -20px;

        &:before {
          font-family: 'FontAwesome';
          margin-right: 7px;
          content: $fa-var-check-circle;
          color: $green;
        }
      }
    }

    .feature-img {
      img {
        width: 100%;
      }
    }
  }

  .feature-block-container {
    .panel {
      a.heading {
        color: black;
        font-size: 20px;
      }
      .body {
        font-size: 18px;
      }
    }
    .feature-header {
      margin-bottom: 75px;

      .feature-header-icon {
        text-align: center;
      }

      .feature-heading {
        text-align: center;
      }
    }
    .feature-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .feature-components {
        .feature-component {
          margin-bottom: 15px;
          .feature-component-header {
            display: flex;

            i {
              color: #8d8d8d;
            }

            .feature-component-title {
              color: #403f3f;
            }
          }
          .feature-component-copy {
            margin-top: 5px;
            font-size: 18px;
            color: #888888;
          }
        }
      }
    }
  }
}

#features-connect {
  .feature-img {
    padding-top: 100px;
    text-align: center;

    img {
      max-width: 400px;
    }
  }
}

#features-statistics,
#features-sharing,
#features-teams {
  .feature-img {
    text-align: center;

    img {
      max-width: 450px;
    }
  }
}

#features-sharing .feature-img {
  padding-top: 60px;
}

#features-statistics .feature-img {
  padding-top: 30px;
}

#features-teams .feature-img {
  padding-top: 10px;
}

#features-app {
  .feature-img {
    padding-top: 10px;
    text-align: center;

    img {
      max-width: 320px;
    }
  }
}

#features-statistics {
  .feature-img {
    margin-bottom: 150px;
    padding-top: 10px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  html#features {
    body #page-content {
      height: auto !important;
    }

    .feature-row {
      padding-bottom: 30px;
      padding-top: 0;

      h2 {
        i.fa {
          color: $yellow;
          background: transparent;
          height: 30px;
          width: 30px;
          line-height: 30px;
          font-size: 24px;
          @include border-radius(none);
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 15px;
      }

      ul li {
        font-size: 16px;
      }

      .feature-img {
        padding-top: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
}
