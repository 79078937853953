#home-header {
  width: 100%;
  padding: 15px;

  .home-header-inner {
    .home-player,
    .home-title-spacer {
      display: none;
    }
  }
  .home-title-text {
    h1.header-title {
      font-size: 40px;
    }
  }
}

#home-brand-banner {
  padding: unset;
  width: 100%;
  margin: 0;
  h3 {
    margin: unset;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .brand-list {
    width: 100%;
    padding: 0;
    text-align: center;
    display: block;

    .brand-item {
      width: auto;
      height: auto;
      display: inline-block;
      min-width: auto;
      img {
        width: 80px;
        padding: 5px;
      }
    }
  }
  .brand-description {
    display: none;
  }
}

.home-feature {
  padding: 20px 15px 0 15px;
  height: auto;

  .home-feature-inner {
    flex-direction: column;
    justify-content: center;
  }

  .feature-spacer {
    display: none;
  }

  .feature-description {
    width: 100%;
    margin-bottom: 20px;
    > h2 {
      font-size: 24px;
    }
    > p {
      font-size: 16px;
    }
  }

  .feature-image {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

#home-feature-keep {
  .home-feature-inner {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

#home-feature-scale {
  .feature-image {
    height: 350px;

    img {
      width: auto;
      height: 100%;
    }
  }
}
