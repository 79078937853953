#password-rating {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .rating-step {
    height: 3px;
    background: #ddd;
    width: 24%;
    &.active {
      background: #46cc46;
    }
  }
}

.container-minimal {
  .panel-minimal {
    max-width: 580px;
    padding: 25px 80px 40px;

    background: white;
    @include border-radius(5px);

    h2 {
      text-align: center;
      font-weight: bolder;
      margin-bottom: 50px;
    }
  }

  .minimal-form {
    background: white;
    padding: 15px 15px 1px;
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .container-minimal {
    .panel-minimal {
      padding: 0 15px 15px;

      h2 {
        margin-bottom: 25px;
      }
    }
  }
}
