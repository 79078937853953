html#features {
  #feature-header-splash {
    height: 650px;
  }

  .feature-hero {
    height: auto;
    display: block;
    margin-top: 25px;

    .feature-hero-content {
      .feature-actions {
        display: block;
        margin-bottom: 25px;

        .btn-lg {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }

    .feature-testimonial {
      // Do not show the video and modal on small screens for now.
      // Maintain styling below in the event that this new
      // requirement changes
      display: none;

      .feature-testimonial-video {
        .feature-poster-img {
          width: 100%;
        }

        .feature-play-button {
          left: 40%;
          top: 30%;
          height: 75px;
        }
      }

      .feature-testimonial-footer {
        width: auto;
        margin: auto;
      }
    }
  }

  .feature-row {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .feature-block-container {
    .feature-header {
      margin-bottom: 0;

      .feature-header-icon {
        text-align: center;
      }

      .feature-heading {
        text-align: center;
      }
    }
  }

  #inline-sign-up-form {
    margin: 0;
  }
}
