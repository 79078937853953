@import '~compass-mixins/lib/compass/css3/border-radius';
@import '~compass-mixins/lib/compass/css3/text-shadow';
@import '~compass-mixins/lib/compass/css3';
@import '~compass-mixins/lib/compass/layout/sticky-footer';
@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import 'breakpoint-sass';

@import 'theme/colours';
@import 'partials/icons';
@import 'partials/flash';
@import './mixins/compass-helpers';

@import 'public/navbar';
@import 'public/home';
@import 'public/pricing';
@import 'public/page';
@import 'public/minimal';
@import 'public/features';
@import 'public/about';
@import 'public/content';
@import 'partials/cookie-consent';
@import 'public/integrations';
@import 'public/blog';
@import 'public/videofunnelplaybook';
@import 'public/product';
@import 'public/ourapps';
@import 'public/split';
@import 'public/one-col-centered';
@import 'public/stories';
@import 'public/register';
@import 'public/password-reset';
@import 'public/partials/box-container';
@import 'public/partials/footer';
@import 'public/partials/testimonials';
@import 'public/partials/inline-signup-form';
@import 'public/teams';
@import 'public/error';

html {
  height: 100%;
}

body {
  background: white;
  position: relative;
  &.marketing-site {
    padding-top: 80px;
    overflow-x: hidden;
    @media (max-width: 768px) {
      padding-top: 60px !important;
    }
  }

  #page-content {
    position: relative;
  }
}

@include sticky-footer(100px, #page-content, #page-footer, #footer);

.alert {
  h3 {
    margin-top: 0;
  }
}

p {
  color: $brown-dark;
  font-size: 16px;
}

@media (min-width: 768px) {
  .panel-minimal {
    max-width: 320px;
    margin: 35px auto;
  }
}

.fa-btn {
  margin-right: 6px;
}

/*
 * Important! Load all the responsive styles over the top of the public ones
 */
@media (max-width: 768px) {
  @import 'public/responsive/page';
  @import 'public/responsive/register';
  @import 'public/responsive/blog';
  @import 'public/responsive/home';
  @import 'public/responsive/pricing';
  @import 'public/responsive/features';
  @import 'public/responsive/integrations';
  @import 'public/responsive/about';
}

.home-feature-list {
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: row;

  .feature-list-sidebar {
    flex: 35%;
    ul {
      list-style: none;
      padding-right: 15px;

      li {
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;

        font-weight: bold;
        font-size: 16px;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid transparent;
        cursor: pointer;
        color: #666;
        user-select: none;

        &.active {
          background: #fafafa;
          border-color: #eee;
          color: $blue;
          box-shadow: 0 2px 10px rgba(black, 0.1);
        }
      }
    }
  }

  .feature-list-content {
    flex: 65%;
    border: 1px solid #ddd;
    border-radius: 4px;

    .list-content-item {
      display: none;
      padding: 10px 25px;
      height: 100%;
      flex-direction: column;
      justify-content: center;

      > p {
        font-size: 18px;
      }

      a.content-item-preview {
        display: flex;
        flex-direction: row;
        color: #444;
        text-decoration: none;
        cursor: pointer;

        .item-preview-img {
          img {
            width: 70px;
            height: 70px;
            border-radius: 4px;
          }
        }

        .item-preview-description {
          padding-left: 15px;
          font-weight: bold;
          font-size: 16px;
          color: #27b3c1;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 1em;

          .item-preview-author {
            font-size: 20px;
            color: #444;
            line-height: 1.2em;
            margin-bottom: 10px;
          }
        }
      }

      &.active {
        display: flex;
      }
    }
  }
}

#home-header {
  height: 600px;
  padding-top: 40px;

  .home-header-inner {
    position: relative;

    .home-player {
      width: 500px;
    }
  }

  .home-title-text {
    width: 560px;
    margin-left: 50px;
    margin-top: 30px;

    .header-title {
      font-family: 'Lato', Arial, san-serif;
      font-weight: bold;
      font-size: 42px !important;
    }
  }
}

.carousel-step {
  height: 400px;
  width: 600px;
  margin-top: 45px;
  left: -53px;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.35s ease-in;

  &.in {
    opacity: 1;
  }

  img {
    width: 100%;
    height: auto;
  }

  .carousel-step-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    span {
      display: inline-block;
      padding: 2px 10px;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 20px;
      color: #999;
      font-size: 12px;
    }
  }
  .carousel-step-desktop {
    position: absolute;
    top: 0;
    left: 130px;

    width: 400px;
    z-index: 5;
    transform: scale(1.2) translateX(40px) translateY(0) perspective(3190px)
      rotateY(-22deg) rotateX(4deg) rotate(1deg);
    box-shadow: -1px -1px 5px 0 rgba(26, 26, 67, 0.05),
      -12px 20px 125px -25px rgba(50, 50, 93, 0.5),
      -18px 20px 75px -37.5px rgba(0, 0, 0, 0.6);
  }
  .carousel-step-mobile {
    position: absolute;
    top: 0;
    left: 0;

    width: 200px;
    z-index: 15;
    transform: scale(0.9) perspective(2910px) rotateY(12deg) rotateX(3deg)
      rotate(-2deg);
    box-shadow: -1px -1px 5px 0 rgba(26, 26, 67, 0.05),
      -12px 20px 125px -25px rgba(50, 50, 93, 0.5),
      -18px 20px 75px -37.5px rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 768px) {
  #home-header {
    height: auto;
    padding: 0;
    padding-bottom: 20px;

    .home-header-inner {
      display: flex;
      flex-direction: column-reverse;

      .home-player {
        .header-carousel {
          position: relative;

          .carousel-step {
            width: 100vw;
            overflow: hidden;
            left: 0;
            height: 440px;
            margin-top: 0;
            .carousel-step-desktop {
              left: 80px;
              transform: scale(1) translateX(0px) translateY(30px)
                perspective(3190px) rotateY(-29deg) rotateX(4deg) rotate(1deg);
            }
            .carousel-step-mobile {
              transform: scale(0.8) translateX(-35px) translateY(60px)
                perspective(2910px) rotateY(26deg) rotateX(3deg) rotate(-2deg);
            }
            .carousel-step-text {
              bottom: 25px;
            }
          }
        }
      }

      .home-title-text {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding: 0 15px;
        h1.header-title {
          font-size: 35px !important;
          letter-spacing: 0.01rem;
        }
      }
    }
  }

  .home-feature-list {
    flex-direction: column;
    .feature-list-sidebar {
      margin-bottom: 10px;
      ul {
        margin: 0;
        padding: 0 15px;
      }
    }
    .feature-list-content {
      border-radius: 0;
      height: 200px;

      .list-content-item {
        padding: 10px;
        .content-item-preview {
          > img {
            display: none;
          }
        }
      }
    }
  }

  .home-workflow-stages {
    max-width: 100%;

    .workflow-stage-item {
      height: auto;
      max-height: none;
      flex-direction: column;

      .stage-item-description {
        padding: 0 15px;
      }

      .stage-item-image {
        width: 100%;
        height: auto;
        padding: 0 15px;

        img {
          width: auto;
          max-height: 200px;
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.app-loader {
  text-align: center;
  height: 100vh;
  justify-content: center;
  padding-top: 20%;
}
