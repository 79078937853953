@import '~compass-mixins/lib/compass/utilities/general/clearfix';

@import '~compass-mixins/lib/compass/css3/box-shadow';
@import '~compass-mixins/lib/compass/css3/transition';
@import '~compass-mixins/lib/compass/css3/user-interface';

#home-brand-banner {
  padding: 40px 0;
  h3 {
    margin: 80px 0 20px;
    font-size: 30px;
  }

  .brand-list {
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .brand-item {
      height: 100px;
      min-width: 170px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        width: 110px;
        height: auto;
      }
    }
  }

  .brand-description {
    min-height: 45px;
    font-size: 1.3em;
    color: #444;
    line-height: 45px;
    text-align: center;
  }
}

.home-feature {
  height: 460px;
  color: white;

  &#home-feature-delight {
    background: #27b3c1;
  }

  &#home-feature-keep {
    background: #fc5a00;
  }

  &#home-feature-scale {
    background: #42332a;

    a#btn-feature-integrations {
      display: inline-block !important;
      width: auto;
      color: $brown-dark;
    }
  }
}

.home-workflow-stages {
  max-width: 1000px;
  margin: 0 auto;

  > h3 {
    margin: 80px 0 20px;
    font-size: 38px;
  }

  .workflow-stage-item {
    min-height: 430px;
    padding: 30px 0;
    display: flex;
    margin-bottom: 30px;

    &.stage-item-integrate {
      flex-direction: row-reverse;
    }
    &.stage-item-contacts {
      flex-direction: row;
    }
    &.stage-item-record {
      flex-direction: column;
      margin-bottom: 45px;

      .stage-item-description {
        text-align: center;
        max-width: 75%;
        margin: 0 auto;
      }

      .stage-item-image {
        text-align: center;

        img {
          width: 90%;
        }
      }
    }
    &.stage-item-responses {
      flex-direction: row-reverse;
    }
    &.stage-item-outcomes {
      flex-direction: column;

      .stage-item-description {
        text-align: center;
        max-width: 75%;
        margin: 0 auto 30px;
      }

      .stage-item-image {
        position: relative;
      }

      .stage-testimonial-container {
        position: absolute;
        top: 220px;
        left: 300px;
        right: 300px;
        bottom: 130px;

        .testimonial-slide {
          background-color: white;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 200px;
          flex: 1;

          .slide-text {
            font-size: 19px;
            line-height: 1.25em;
            margin-bottom: 30px;
          }
          .slide-author {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

    .stage-item-description {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 40px;

      h4 {
        font-size: 30px;
        font-family: 'Circular Std', san-serif;
        color: #333;
        line-height: 1.25em;
        margin-bottom: 25px;
      }
      p {
        font-size: 17px;
        color: #888;
      }
    }
    .stage-item-image {
      flex: 50%;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;

        max-height: 100%;
      }
    }
  }
}

#home-twitter-feed {
  height: 480px;
  position: relative;

  .twitter-fade-left,
  .twitter-fade-right {
    width: 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
  }

  .twitter-fade-left {
    left: 0;
    @include background-image(linear-gradient(to left, transparent, white));
  }

  .twitter-fade-right {
    right: 0;
    @include background-image(linear-gradient(to right, transparent, white));
  }

  > h2 {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 30px;
    font-family: 'Circular Std', sans-serif;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -10px;
  }

  .owl-carousel {
    position: relative;
    z-index: 1;

    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          height: 300px;
        }
      }
    }
  }
}

#home-bottom-cta {
  padding: 20px 0 70px 0;

  #btn-ready-to-rumble {
    padding: 0 25px;
    font-size: 22px;
    line-height: 50px;
  }
}

.home-feature {
  .home-feature-inner {
    max-width: 970px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .feature-description {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 35px;
      font-family: 'Circular Std', 'Lato', sans-serif;
    }

    p {
      color: white;
      font-size: 20px;
      line-height: 1.4em;
    }
  }

  .feature-image {
    width: 550px;
    overflow: visible;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .feature-image-inner {
      height: 100%;
      background-repeat: no-repeat;
      background-size: auto;
      border: 1px solid pink;

      img {
        vertical-align: bottom;
      }
    }
  }

  .feature-spacer {
    width: 40px;
  }

  #bg-feature-delight {
    align-items: flex-start;
  }

  #bg-feature-keep {
    align-items: flex-end;
  }

  #bg-feature-scale {
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }
}

.btn-fb {
  background: $facebook-blue;
}
.btn-tw {
  background: $twitter-blue;
}
.btn-em {
  background: $orange;
}

html#home {
  .navbar.navbar-default {
    background: none;
  }
}

.home-mobile-header {
  display: none;
}

#home-header {
  max-width: 1100px;
  padding: 20px 0;
  margin: 0 auto;

  .home-header-inner {
    display: flex;
    flex-direction: row;

    .home-player {
      width: 500px;
    }

    .home-hello-bear {
      margin-top: 100px;
    }
  }

  .home-player {
    display: flex;
    flex-direction: row;

    .player-inner {
      width: 370px;
      @include box-shadow(0 1px 4px rgba(black, 0.2));
      @include border-radius(10px);
      justify-content: space-between;

      .video-container {
        height: 370px;
        width: 370px;
        position: relative;
        overflow: hidden;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include user-select(none);

        #video-controls {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            .play-button {
              display: block;
            }
          }

          .play-button {
            width: 120px;
            height: 120px;
            padding: 0;
            display: none;
            font-size: 70px;
            text-align: center;
            color: #ffffff;
            line-height: 120px;
            cursor: pointer;

            > svg {
              height: 110px;
              width: 110px;
              #ic_play_thick_bg {
                fill: rgba(grey, 0.75);
              }
            }

            &:hover {
              opacity: 1;
            }
            &.active {
              display: block;
            }

            #pause-icon {
              display: inline-block;
            }

            #play-icon {
              display: none;
            }

            i.fa.fa-play {
              display: none;
            }
            i.fa.fa-pause {
              display: inline-block;
            }

            &.playing {
              #pause-icon {
                display: none;
              }

              #play-icon {
                display: inline-block;
              }

              i.fa.fa-play {
                display: inline-block;
              }
              i.fa.fa-pause {
                display: none;
              }
            }
          }

          .volume-button {
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 15px;
            right: 5px;
            font-size: 40px;
            color: rgba(255, 255, 255, 0.5);
            text-shadow: 0 0 10px white;
            text-align: center;
            line-height: 50px;
            i.fa.fa-volume-off {
              display: none;
            }
            i.fa.fa-volume-up {
              display: inline-block;
            }
            &.on {
              i.fa.fa-volume-off {
                display: inline-block;
              }
              i.fa.fa-volume-up {
                display: none;
              }
            }
          }
        }

        .progress {
          height: 10px;
          margin: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          overflow: hidden;
          @include border-radius(0);
          @include box-shadow(none);
          background-color: rgba(51, 51, 51, 0.68);

          .progress-bar {
            width: 0%;
            height: 100%;
            float: left;
            @include box-shadow(none);
            font-size: 12px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            background-color: $yellow;
            @include transition(width 0.1s ease);
          }
        }
      }
    }

    .profile-container {
      min-height: 65px;
      padding: 15px;
      background: white;
      @include clearfix;
      display: flex;
      flex-direction: row;
      align-items: center;

      #profile-img {
        width: 50px;
        height: 50px;
        @include border-radius(50px);
        background: #ddd;
        @include border-radius(50px);
      }

      .profile-content {
        margin-left: 10px;

        #profile-name {
          font-size: 19px;
        }

        #profile-info {
          font-size: 15px;
          line-height: 1.3em;
        }
      }
    }

    .explainer-container {
      height: 80px;
      padding: 20px 20px;

      > .btn {
        line-height: 40px;
        padding: 0;
        font-size: 17px;
      }
    }
  }

  .home-title-spacer {
    width: 45px;
  }

  .home-hello-bear {
    width: 130px;
    height: 350px;
    background: image-url('homepage/hello-bear.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .home-title-text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .brand-container {
      img.brand-bear {
        height: 32px;
        max-width: unset;
      }
    }

    .header-title {
      margin-top: 25px;
      margin-bottom: 20px;
      font-size: 45px;
      color: #544238;
      font-weight: bold;
      line-height: 1.15em;
      font-family: 'Circular Std', sans-serif;
    }

    .header-description {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .header-actions {
      width: 250px;

      .btn-block {
        border-radius: 5px;
        font-size: 20px;
      }
    }

    .header-login {
      padding: 10px 0;
      font-size: 15px;
      color: #999;
    }
  }
}

.home-icon {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
  85% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.home-icon {
  svg {
    width: 60px;
    height: 60px;
  }
}

.home-icon-love {
  .love-heart {
    animation: heartbeat 5s infinite;
    transform-origin: 50%;
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home-icon-pricing {
  .dollar-sign {
    animation: rotate 5s infinite;
    transform-origin: 50%;
    animation-delay: 1.5s;
  }
}

@-webkit-keyframes flip {
  0% {
    transform: scaleX(1);
  }
  45% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  95% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none;
  }

  .home-player {
    display: none;
  }

  .home-header {
    padding-top: 0;
    padding-bottom: 30px;

    .header-title {
      font-size: 48px;
    }
  }
  .profile-container {
    display: none;
  }

  .home-mobile-header {
    display: block;
    padding: 10px 0 40px;

    h1.header-title {
      color: #534027;
      font-size: 41px;
      font-weight: bold;
    }

    .header-actions {
      padding: 15px 0 0;
    }

    .header-login {
      padding: 15px 0;
    }
  }

  #home-twitter-feed {
    .twitter-fade-left,
    .twitter-fade-right {
      display: none;
    }
  }

  .home-workflow-stages {
    > h3 {
      font-size: 30px;
    }

    .workflow-stage-item {
      &.stage-item-integrate,
      &.stage-item-contacts,
      &.stage-item-record,
      &.stage-item-responses,
      &.stage-item-outcomes {
        flex-direction: column !important;
        min-height: 0;
      }
      &.stage-item-record .stage-item-description {
        max-width: 100%;
        text-align: left;
      }
      &.stage-item-outcomes {
        .stage-item-description {
          max-width: 100%;
          text-align: left;
        }
        .stage-item-image {
          display: none;
        }
      }
    }
  }
}

.no-flexbox {
  .profile-container {
    display: table;
    min-width: 100%;

    > * {
      display: table-cell;
    }

    #profile-img {
      margin-right: 5px;
    }
  }
}
