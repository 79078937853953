$tablet: max-width 768px;
$laptop: max-width 1280px;

@import '../theme/text/mixins';

html#team-funnels {
  h1 {
    @include HeroTitle;
    font-weight: 900;

    @include breakpoint($tablet) {
      font-size: 30px;
    }
  }
  h1.card-heading {
    @include Heading;

    @include breakpoint($tablet) {
      font-size: 16px;
    }
  }

  h2 {
    @include Heading;

    @include breakpoint($tablet) {
      font-size: 16px;
    }
  }
  h3.banner-subtitle {
    @include BannerSubtitle;
    @include breakpoint($tablet) {
      font-size: 20px;
    }
  }
  h3.copy-heading {
    @include CopyHeading;
  }
  p {
    @include BannerCopy;
    @include breakpoint($tablet) {
      font-size: 16px;
      line-height: 1.63;
    }
  }

  .team-funnels-hero {
    height: 679px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: initial;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: auto 20px;
    .hero-description {
      max-width: 542px;
    }
    @include breakpoint($tablet) {
      background-image: none !important;
      height: 145px;
      margin-top: 20px;
      .hero-title {
        margin: auto 20px 40px;
      }
      .hero-description {
        max-width: 252px;
        align-self: center;
      }
    }
  }

  .team-funnels-text-slider {
    max-width: 606px;
    text-align: center;
    align-self: center;
    @include breakpoint($tablet) {
      display: none;
    }
  }

  .team-header-banner {
    height: 655px;
    width: 100%;
    align-self: center;
    background-position: right bottom;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 85%);
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    padding: 0 0 100px 278px;
    margin: auto 0;
    .team-hero-title {
      max-width: 492px;
    }
    .team-hero-description {
      max-width: 395px;
    }
    a {
      type: button;
      border-radius: 5px;
      background-color: #ffffff;
      height: 50px;
      max-width: 224px;
      text-align: center;
      padding-top: 10px;
      color: #27b3c1;
      border: solid 1px #cdcdcd;
      font-size: 20px;
    }
    a:hover {
      background: $grey-light-alt;
      cursor: pointer;
    }
    @include breakpoint($tablet) {
      width: 100% !important;
      height: auto !important;
      padding: 20px;
      background-image: none !important;
      background-color: unset !important;
      clip-path: unset;
      .team-hero-title {
        margin-top: 20px;
      }
      .team-hero-description {
        margin: 20px 0 20px 0;
      }
    }
  }

  .team-contents-text-slider {
    max-width: 704px;
    text-align: left;
    align-self: center;
    @include breakpoint($tablet) {
      width: 100% !important;
      padding: 20px;
    }
  }

  .popular-funnels-container {
    .banner-subtitle {
      margin: 30px 0 15px 0;
    }
    .popular-funnel-card-container {
      margin: 25px 0;
      .text-section {
        overflow: hidden;
        width: 334px;
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .funnel-description {
          margin: 0;
        }
      }
      a {
        align-self: flex-start;
        type: button;
        border-radius: 5px;
        background-color: #ffd002;
        height: 39px;
        width: 110px;
        text-align: center;
        padding-top: 9px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
      }
      a:hover {
        background-color: #f0c300;
        cursor: pointer;
      }
    }
    @include breakpoint($tablet) {
      display: block;
      text-align: left;
      img {
        width: 100%;
        height: auto;
      }
      .text-section {
        width: 252px;
      }
      a {
        width: 252px !important;
      }
    }
  }

  .view-all-funnels {
    align-self: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 568px;
    .link-text {
      color: #27b3c1;
    }
    @include breakpoint($tablet) {
      width: 100% !important;
      height: auto !important;
      padding: 20px;
    }
  }

  .learn-more {
    .learn-more-title {
      align-self: center;
      max-width: 634px;
    }
    align-self: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    .link-text {
      color: #27b3c1;
    }
    @include breakpoint($tablet) {
      width: 100% !important;
      height: unset !important;
      padding: 20px;
      background-image: none !important;
      background-color: unset !important;
    }
  }

  .spinner {
    position: fixed;
    left: 50%;
  }

  .team-funnels-banners {
    display: block;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    @include breakpoint($tablet) {
      max-width: 100%;
      padding: 0;
    }
  }

  .product-list {
    padding-top: 100px;
    margin: 0 auto;
    @include breakpoint($tablet) {
      max-width: 252px;
    }
  }

  .product-list-banner {
    text-align: center;
    h2 {
      font-weight: 900;
    }
    @include breakpoint($tablet) {
      width: 100%;
      h1 {
        display: none;
      }
    }
  }

  .inline-registration {
    h1 {
      font-size: 32px;
    }
    width: 1170px;
    margin: 0 auto;
    .row {
      width: 100%;
      margin: 15px auto;
    }
    .col-md-5 {
      text-align: left;
    }

    @include breakpoint($tablet) {
      width: 100%;
      h1 {
        font-size: 30px;
      }
      .row {
        display: block;
      }
      .col-md-5 {
        padding: 0 40px 0 40px;
        width: 100%;
      }
    }
  }

  .inspiration-banner {
    h1 {
      padding-bottom: 40px;
    }
    p {
      padding-top: 10px;
      color: #d0577d;
    }
    h2 {
      margin-top: -5px;
    }
    @include breakpoint($tablet) {
      h1 {
        padding-bottom: 20px;
      }
      img {
        width: 100%;
      }
    }
  }
}
