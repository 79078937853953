.testimonial-customer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 115px;
  margin-bottom: 115px;
  padding: 25px;

  .testimonial-color-splash {
    position: absolute;
    width: 100vw;
    overflow: hidden;
    z-index: -1;

    &.splash-top {
      height: 250px;
      margin-top: -250px;
      background-image: linear-gradient(
        182deg,
        #fff 70%,
        $sunflower-yellow calc(70% + 1px)
      );

      &.splash-teal {
        background-image: linear-gradient(
          182deg,
          #fff 70%,
          $tealish-two calc(70% + 1px)
        );
      }
    }

    &.splash-bottom {
      margin-top: 0;
      height: 445px;
      background-image: linear-gradient(
        178deg,
        $sunflower-yellow 70%,
        #fff calc(70% + 1px)
      );

      &.splash-teal {
        background-image: linear-gradient(
          178deg,
          $tealish-two 70%,
          #fff calc(70% + 1px)
        );
      }
    }

    &.splash-flip-x {
      transform: scaleX(-1);
    }
  }

  .testimonial-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    align-items: center;

    .testimonial-text {
      max-width: 650px;
      margin-right: 50px;
      margin-top: 10px;

      .testimonial-quote {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: bold;
      }

      .testimonial-author-name {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .testimonial-author-image {
      height: 215px;
      margin-top: 20px;
    }

    // Update flip margin spacing and row direction on splash testimonial
    &.flip-x {
      flex-direction: row-reverse;

      .testimonial-text {
        margin-left: 50px;
        margin-right: 0;
      }
    }
  }

  &.light {
    color: $white;
  }
}

@media (max-width: 1024px) {
  .testimonial-customer {
    padding: 15px;
    .testimonial-container {
      .testimonial-author-image {
        margin: auto;
        margin-bottom: 5vw;
      }
      .testimonial-text {
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }

      &.flip-x {
        .testimonial-text {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .testimonial-color-splash.splash-bottom {
      height: 780px;
    }
  }
  .testimonial-author-image {
    height: 320px !important;
  }
}
