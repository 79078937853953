.content-container {
  @media (min-width: 950px) {
    width: 950px;
  }

  h1 {
    margin-bottom: 1.5em;
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 1.5em;
  }
}
