html#about {
  p {
    font-family: Lato, Arial, sans-serif;
  }

  h1,
  h2,
  h3 {
    font-family: 'Circular Std', san-serif;
  }

  .about-header {
    max-width: 1400px;
    height: 650px;
    display: flex;
    margin: 0 auto;
    padding-left: 105px;
    flex-direction: column;
    justify-content: center;
    background-image: image-url('about/about-header.png');
    background-repeat: no-repeat;
    background-size: cover;

    .about-header-text {
      margin-bottom: 30px;
    }

    h1 {
      font-family: 'Circular Std', sans-serif;
      font-size: 44px;
      margin-bottom: 15px;
    }

    p {
      line-height: 1.25em;
      font-size: 20px;
    }

    p,
    h1 {
      color: white;
      font-weight: bold;
      text-shadow: 0 0 5px rgba(black, 0.2);
    }
  }

  .about-team,
  .about-bears,
  .about-bonjoro,
  .about-values,
  .about-jobs {
    margin: 40px auto;
  }

  .about-bonjoro {
    .container {
      padding: 0;
      background-image: image-url('about/about-processes.png');
      background-position: right;
      background-size: 75%;
      background-repeat: no-repeat;
    }

    #about-processes {
      display: none;
    }

    h2 {
      font-family: 'Circular Std', sans-serif;
      font-weight: bold;
      line-height: 1.25em;
      font-size: 30px;
    }

    h2,
    p {
      max-width: 375px;
      text-align: left;
    }

    .about-story-image {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 90%;
        @include border-radius(10px);
      }
    }

    .about-story {
      text-align: justify;
      img {
        vertical-align: sub;
      }

      p {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  .about-team {
    .team-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .team-member {
        max-width: 25%;

        .bear-suit {
          padding: 0 10px;
          margin-top: -35px;
          transform: scale(0.65);

          .bear-svg {
            width: 223px;
            height: 373px;

            .invert {
              opacity: 0.7;
            }

            &:hover {
              cursor: pointer;
              .invert {
                opacity: 1;
              }
            }
          }
        }

        .team-info {
          text-align: center;
          margin-top: -35px;

          h4 {
            font-size: 16px;
          }
          h5 {
            font-size: 13px;
          }
        }
      }

      .hiring {
        max-height: 310px;
        max-width: 800px;
        margin: 25px auto 0;
        display: flex;
        background: #f5f5f5;
        border-radius: 5px;

        .hiring-info {
          max-width: 65%;
          margin-top: 45px;
          margin-left: 5%;
        }
      }
    }

    .about-values {
      .badges {
        margin: 35px 0;
        img {
          width: 100%;
        }
      }
      .values {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .value-column {
          display: flex;
          flex-direction: column;

          .value-item {
            display: flex;
            flex-direction: column;
            padding: 15px 15px 25px 15px;

            h3 {
              margin-top: 0;
              margin-bottom: 10px;
            }

            p {
              font-size: 15px;
            }

            p.lead {
              font-size: 17px;
              margin-bottom: 15px;
            }

            &.center-item {
              min-height: 285px;
              align-items: center;

              img {
                width: 200px;
                z-index: 1;
              }
            }

            &.featured-item {
              margin-top: -150px;
              background: #f5f5f5;
              padding: 15px;
              margin-bottom: 20px;

              h3 {
                margin-top: 80px;
              }
            }
          }
        }
      }
    }

    .about-jobs {
      width: 100%;

      .job-columns {
        display: flex;
        flex-direction: row;
        min-width: 50%;

        img {
          width: 100%;
        }

        .job-item {
          width: 100%;
          padding: 0 10px;
          text-align: left;
        }
      }
    }
  }

  .team-player {
    width: 420px;
    position: fixed;
    left: 50%;
    top: 40%;
    margin-left: -256px;
    margin-top: -230px;
    overflow: hidden;
    z-index: 99999;

    @include border-radius(10px);

    .video-container {
      height: 420px;
      width: 420px;
      position: relative;
      overflow: hidden;
      @include border-radius(10px 10px 0 0);
      @include user-select(none);

      .close-player {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 200000;
        color: white;
        font-size: 24px;
        text-align: center;

        .fa.fa.close {
          text-shadow: 0.1rem 0.1rem 0.2rem rgba(#222, 0.6);
        }

        &:hover {
          cursor: pointer;
          color: $red;
        }
      }

      .video-hitbox {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: table;
        vertical-align: middle;
        height: 100%;
        width: 100%;
        z-index: 100000;
      }

      .video-play-box {
        position: absolute;
        top: 160px;
        left: 160px;
        z-index: 10000;

        .video-play-icon {
          width: 120px;
          height: 120px;
          padding: 0;
          @include border-radius(120px);
          background: $yellow;
          opacity: 0.75;
          font-size: 70px;
          text-align: center;
          color: #ffffff;
          line-height: 120px;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        .fa-play {
          width: 45px;
          height: 80px;
        }
      }

      #video-controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          .play-button {
            display: block;
          }
        }

        .play-button {
          width: 120px;
          height: 120px;
          padding: 0;
          display: none;
          @include border-radius(120px);
          background: $yellow;
          opacity: 0.75;
          font-size: 70px;
          text-align: center;
          color: #ffffff;
          line-height: 120px;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
          &.active {
            display: block;
          }

          i.fa.fa-play {
            display: none;
          }
          i.fa.fa-pause {
            display: inline-block;
          }

          &.playing {
            i.fa.fa-play {
              display: inline-block;
            }
            i.fa.fa-pause {
              display: none;
            }
          }
        }

        .volume-button {
          width: 50px;
          height: 50px;
          position: absolute;
          bottom: 15px;
          right: 5px;
          font-size: 40px;
          color: rgba(255, 255, 255, 0.5);
          text-shadow: 0 0 10px white;
          text-align: center;
          line-height: 50px;
          i.fa.fa-volume-off {
            display: none;
          }
          i.fa.fa-volume-up {
            display: inline-block;
          }
          &.on {
            i.fa.fa-volume-off {
              display: inline-block;
            }
            i.fa.fa-volume-up {
              display: none;
            }
          }
        }
      }

      .progress {
        height: 10px;
        margin: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        @include border-radius(0);
        @include box-shadow(none);
        background-color: rgba(51, 51, 51, 0.68);

        .progress-bar {
          width: 0%;
          height: 100%;
          float: left;
          @include box-shadow(none);
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          text-align: center;
          background-color: $yellow;
          @include transition(width 0.1s ease);
        }
      }
    }

    .profile-container {
      height: 90px;
      padding: 15px;
      background: white;
      @include clearfix;
      display: flex;
      flex-direction: row;
      align-items: center;

      #profile-img {
        width: 50px;
        height: 50px;
        @include border-radius(50px);
      }

      .profile-content {
        margin-left: 10px;

        #profile-name {
          font-size: 19px;
        }

        #profile-info {
          font-size: 15px;
          line-height: 1.1em;
        }
      }
    }
  }
}
