@import '../theme/palette';

.js-cookie-consent {
  z-index: 99;
  position: fixed;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  background: white;
  text-align: center;
  color: $tealish-two;
  box-shadow: 0 -4px 4px 0 rgba(166, 166, 166, 0.13);

  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .js-cookie-container {
    max-width: 1080px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .cookie-header {
    display: flex;
    justify-content: center;
  }

  .content {
    p.cookie-consent__message {
      color: #7d7c7c;
      font-size: 14px;
      font-family: 'Lato';
      text-align: center;
      margin: 12px 1px 4px 0;
      line-height: 1.5;
      letter-spacing: 0.05px;
    }

    p.cookie-consent__policy {
      color: #8d8d8d;
      font-size: 12px;
      font-family: 'Lato';
      text-align: center;
      margin: 4px 0 12px;
      line-height: 1.5;
      letter-spacing: 0.05px;
    }
  }

  .cookie-image-container {
    height: 120px;
    width: 150px;
    overflow: visible;
    position: relative;

    img.cookie-image {
      height: 160px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &.mobile {
      width: 65px;
      height: 40px;
      img {
        height: 70px;
      }
      @media (max-width: 768px) {
        display: inline-block;
      }
    }
  }

  button.js-cookie-consent-agree {
    margin: auto 5px;
    width: 120px;
    height: 36px;
    @media (max-width: 768px) {
      margin: 10px auto 20px;
    }
  }
}
