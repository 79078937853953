.story-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(black, 0.4);
  text-align: center;
  display: none;
  z-index: 150;
  &.in {
    display: block;
  }

  .customer-story-modal {
    width: auto;
    margin: 12% auto;

    .modal-inner {
      display: inline-block;
      background: white;
      @include border-radius(4px);
      padding: 15px;
      width: auto;
      position: relative;

      .modal-close {
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 0;
        line-height: 39px;
        text-align: center;
        position: absolute;
        color: $red;
        top: -50px;
        right: 0;
        background: white;
        @include border-radius(40px);
        &:hover {
          cursor: pointer;
          background: #eee;
        }
      }
    }
  }
}

.customer-story {
  margin-top: -20px;

  a.story-cta {
    line-height: 50px;
    display: inline-block;
    padding: 0 35px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 22px;
    background: #444;
    color: white;
    @include transition(background-color 0.2s ease-in);

    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  .story-header {
    height: 500px;

    .story-header-container {
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      height: 100%;

      .story-header-image,
      .story-header-description {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
      }

      .story-header-image {
        img.header-img-default {
          height: auto;
          width: 100%;
          cursor: pointer;
        }
        img.header-img-mobile {
          display: none;
        }
      }

      .story-header-description {
        padding: 30px 45px 25px 40px;
        max-width: 600px;

        h1 {
          font-size: 50px;
          margin-top: 0;
          margin-bottom: 10px;
          color: #503f33;
          font-weight: 900;
        }
        h4 {
          font-size: 22px;
          margin-top: 0;
          line-height: 1.2em;
          color: #444;
        }

        .story-header-logos {
          margin-bottom: 0;
          height: 100px;

          img {
            margin-right: 15px;
            max-width: 150px;
            max-height: 100px;
          }
        }
      }
    }
  }

  .story-top-quotes {
    min-height: 135px;
    padding: 30px;
    background: #f3f3f3;
    text-align: center;
    font-size: 24px;

    blockquote {
      max-width: 850px;
      margin: 0 auto 5px auto;
      padding: 0;
      border: none;
      font-size: 22px;
      line-height: 1.4em;
      color: #333;
    }

    .quote-author {
      color: #888;
      font-size: 18px;
      line-height: 35px;

      img.quote-author-img {
        width: 35px;
        height: 35px;
        @include border-radius(35px);
      }
    }
  }

  .story-content-sections {
    max-width: 1050px;
    margin: 0 auto;

    .content-section {
      display: flex;
      min-height: 380px;
      align-items: center;
      &:last-child {
        margin-bottom: 50px;
      }

      &:nth-child(odd) {
        flex-direction: row;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      .section-image {
        flex: 1 1 50%;
        padding: 15px;
        text-align: center;

        img {
          width: auto;
          max-width: 100%;
          max-height: 300px;
        }
      }
      .section-info {
        flex: 1 1 50%;
        padding: 0 15px;
        .section-info-title {
          font-size: 34px;
          font-weight: 900;
          line-height: 1.25em;
          color: $brown-dark;
          margin-bottom: 10px;
        }
        .section-info-description {
          font-size: 20px;
          line-height: 1.4em;
        }
      }
    }
  }

  .story-integration-logos {
    height: 310px;
    background: #f3f3f3;
    text-align: center;
    padding: 30px;

    h3 {
      font-size: 30px;
      font-weight: 900;
      margin-bottom: 25px;
      color: $brown-dark;
    }

    .integration-logo-list {
      margin-bottom: 25px;

      a {
        padding: 10px;

        img {
          max-width: 180px;
          max-height: 120px;
        }
      }
    }

    > a {
      font-size: 20px;
    }
  }

  .story-case-studies {
    padding: 30px;

    > h3 {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      color: $brown-dark;
    }
    > h4 {
      font-size: 22px;
      text-align: center;
      color: #888;
      margin-bottom: 40px;
    }

    .case-study-list {
      max-width: 1150px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .case-study-item {
        text-align: center;
        flex: 0 0 25%;

        .item-thumbnail {
          padding: 5px;
          position: relative;
          display: inline-block;
          cursor: pointer;

          img {
            width: 200px;
            height: 200px;
            @include border-radius(4px);
          }

          .item-play-button {
            position: absolute;
            text-align: center;
            bottom: 15px;
            left: 15px;
            width: 50px;
            height: 50px;
            color: white;
            padding-left: 2px;
            font-size: 25px;
            background: rgba($blue, 0.8);
            line-height: 48px;
            @include border-radius(50px);
          }
        }

        .video-embed-code {
          display: none;
        }

        .item-author-name {
          font-size: 22px;
          color: $brown-dark;
        }
        .item-author-job {
          font-size: 17px;
          color: #666;
        }
      }
    }
  }

  .story-bottom-quotes {
    height: auto;
    padding: 45px 0 10px;
    text-align: center;
    font-size: 24px;

    .bottom-quote-item {
      max-width: 820px;
      margin: 0 auto 30px;
    }

    blockquote {
      margin: 0 0 5px 0;
      padding: 0;
      border: none;
      font-size: 22px;
      font-family: Georgia, serif;
      line-height: 1.4em;
      color: #333;
    }

    .quote-author {
      color: #888;
      font-size: 19px;
      margin-bottom: 30px;
      img.quote-author-img {
        width: 35px;
        height: 35px;
        @include border-radius(35px);
      }
      span.quote-author-name {
        display: inline-block;
        vertical-align: middle;
        padding-left: 5px;
      }
    }
  }

  .story-footer-cta {
    padding: 10px 30px 40px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .customer-story {
    a.story-cta {
      line-height: 45px;
      display: inline-block;
      padding: 0 30px;
      border-radius: 6px;
      font-weight: bold;
      font-size: 18px;
      background: #444;
    }

    .story-header {
      width: 100%;
      height: auto;

      .story-header-container {
        flex-direction: column-reverse;
        text-align: center;

        .story-header-image {
          img.header-img-default {
            display: none;
          }
          img.header-img-mobile {
            height: auto;
            width: 100%;
            display: inline-block;
          }
        }

        .story-header-logos {
          overflow: hidden;
        }

        .story-header-cta {
          margin-bottom: 15px;
        }

        .story-header-description {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-end;
          padding: 15px;

          h1 {
            font-size: 32px;
            margin: 20px 0 10px 0;
          }
          h4 {
            font-size: 17px;
            margin-bottom: 0;
          }
        }
      }
    }

    .story-top-quotes {
      height: auto;
      font-size: 18px;
      padding: 15px;

      blockquote {
        font-size: 18px;
      }
    }

    .story-content-sections {
      .content-section {
        display: flex;
        flex-direction: column !important;
        height: auto;
        align-items: center;
        padding-bottom: 25px;

        .section-image {
          padding: 30px;
        }
        .section-info {
          flex: 1 1 auto;

          .section-info-title {
            font-size: 24px;
          }
          .section-info-description {
            font-size: 18px;
          }
        }
      }
    }

    .story-integration-logos {
      height: auto;
      h3 {
        font-size: 24px;
      }
      > a {
        font-size: 17px;
      }
      .integration-logo-list {
        a {
          display: inline-block;
          img {
            max-width: 140px;
            max-height: 80px;
          }
        }
      }
    }

    .story-case-studies {
      padding: 15px;

      > h3 {
        font-size: 24px;
      }
      > h4 {
        font-size: 18px;
      }

      .case-study-list {
        .case-study-item {
          flex: 0 0 50%;
          margin-bottom: 10px;

          .item-thumbnail {
            img {
              max-height: 130px;
              width: auto;
            }
          }
          .item-author-name {
            font-size: 18px;
          }
          .item-author-job {
            font-size: 14px;
          }
          .item-author-logo {
            img {
              max-height: 60px;
              width: auto;
            }
          }
        }
      }
    }

    .story-bottom-quotes {
      .bottom-quote-item {
        padding: 0 15px;

        blockquote {
          font-size: 15px;
        }
      }
    }
  }
  .story-modal-overlay .customer-story-modal {
    width: 100%;

    margin: 25% 0 0 0;

    .modal-inner {
      width: 100%;
      padding: 0;

      iframe {
        width: 100% !important;
      }

      .modal-close {
        bottom: 0;
        top: initial;
        right: 45px;
      }
    }
  }
}
