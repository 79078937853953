.integrations-header {
  height: 210px;
  text-align: center;

  form {
    max-width: 350px;
    margin: 0 auto;
  }
}

.integration-categories {
  h4 {
    padding: 0 10px;
  }
  .nav.nav-pills.nav-stacked {
    li {
      &.active,
      &:hover {
        a {
          background: $yellow;
        }
      }

      a {
        padding: 5px 10px;
        font-weight: bold;
      }
    }
  }
}

#integrations-index {
  .integrations-create-list {
    border: 1px solid #eee;
    @include border-radius(5px);

    .integration-create-item {
      height: 70px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eee;
      text-decoration: none;
      color: #444;
      position: relative;

      &:hover {
        background: #fafafa;
      }

      &.inactive {
        background: #fafafa;
      }

      .item-inactive {
        color: #888;
      }

      .item-img,
      .item-details {
        display: flex;
        align-items: center;
      }

      .item-img {
        height: 50px;
        width: 5%;
        img {
          width: 40px;
          height: auto;
        }
      }

      .item-details {
        width: 80%;
        padding: 0 15px;
        line-height: 50px;

        .item-title {
          font-size: 22px;
          margin-right: 15px;
          font-weight: bold;
        }

        .item-description {
          font-size: 17px;
          color: #777;
        }
      }

      .item-extra {
        width: 15%;
        font-size: 17px;
        display: block;
        line-height: 50px;
        font-weight: bold;
        text-align: right;

        .item-see-more {
          color: $blue;
        }
      }
    }
  }
}

#integrations-show {
  .integrations-description {
    background: #fafafa;
    padding: 15px;

    .info-logo {
      text-align: center;
      padding: 20px 0;
    }

    .integration-info-desc {
      p,
      ul li {
        font-size: 16px;
      }
    }
  }

  .zap-templates-title {
    text-align: left;
  }
}

.integration-tag-list {
  .integration-tag {
    background-color: #ffce00;
    border-radius: 30px;
    color: #444;
    font-size: 1.3em;
  }
}
