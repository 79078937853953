$mobileLarge: max-width 425px;
$tablet: max-width 768px;
$laptop: max-width 1280px;

.container.product {
  padding-top: 100px;
  @include breakpoint($mobileLarge) {
    padding: 0 35px;
  }
  .header {
    width: 1200px;
    display: flex;
    padding-bottom: 100px;
    .header-content {
      margin: 45px;
      width: 415px;
      .header-cta {
        padding-top: 15px;
      }
      @include breakpoint($mobileLarge) {
        margin: unset;
      }
    }
    .header-image {
      img {
        width: auto;
        max-width: 690px;
      }
      @include breakpoint(max-width 1050px) {
        display: none;
      }
    }
    @include breakpoint($laptop) {
      width: 100%;
    }
    @include breakpoint($mobileLarge) {
      padding-bottom: 25px;
    }
  }
}

.section {
  &.decriptions {
    padding-bottom: 100px;
    @include breakpoint($mobileLarge) {
      display: none;
    }
    .highlights {
      .highlight {
        display: flex;
        justify-content: space-between;
        &:nth-child(odd) {
          flex-direction: row-reverse;
        }
        .content {
          width: 440px;
          margin: auto 45px;
        }
        .image {
          img {
            width: 100%;
            max-width: 530px;
          }
          @include breakpoint($mobileLarge) {
            display: none;
          }
        }
      }
    }
  }

  &.features {
    padding-bottom: 100px;
    @include breakpoint($mobileLarge) {
      padding-bottom: 25px;
    }
    .summary {
      margin-bottom: 75px;
      .mobile-body {
        display: none;
      }
      @include breakpoint($mobileLarge) {
        .mobile-body {
          display: block;
        }
        .body {
          display: none;
        }
      }
    }
    .list {
      margin: 20px;
      min-height: 500px;
      position: relative;
      @include breakpoint($tablet) {
        margin: unset;
      }
      .panel {
        padding: 0;
        background-color: transparent;
        border-width: 0;
        box-shadow: none;
        width: 385px;
        margin-top: 5px;
        margin-bottom: 50px;
        @include breakpoint($tablet) {
          width: 100%;
          margin-bottom: 0;
        }
        .heading {
          display: flex;
          margin-bottom: 15px;
          place-items: center;
          .fas {
            font-size: 18px;
            margin-left: 20px;
            color: #403f3f;
          }
          img {
            width: 27px;
            height: 27px;
            margin-right: 20px;
          }
          h1,
          h2,
          h3 {
            margin: unset;
          }
          @include breakpoint($tablet) {
            img {
              margin-right: 15px;
            }
            .fas {
              margin-left: 5px;
            }
          }
        }
        .body {
          margin-left: 45px;
          overflow: hidden;
          width: 340px;
          transition: height 0.5s ease-out;
          height: 0;
        }
        .feature-image {
          position: absolute;
          right: 0;
          top: 0;
          width: 60%;
          max-width: 650px;
          z-index: -1;
          object-fit: contain;
          object-position: top;
          opacity: 0;
          transition: opacity 1s;
          @include breakpoint($tablet) {
            position: relative;
            width: 100%;
            max-height: 320px;
            height: 0;
          }
        }
        &.in {
          .heading .fas {
            transform: rotate(180deg);
          }
          .feature-image {
            opacity: 1;
            height: unset;
            @include breakpoint($tablet) {
              margin-bottom: 40px;
            }
          }
          .body {
            height: auto;
          }
        }
      }
    }
  }

  &.product-list {
    margin-bottom: 50px;
    width: 1440px;
    margin-left: -140px;
    text-align: center;
    @include breakpoint($tablet) {
      width: 100%;
      justify-content: center;
      margin: auto 0;
      width: 100%;
    }
    .summary {
      margin-bottom: 75px;
      @include breakpoint($tablet) {
        display: none;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      @include breakpoint($tablet) {
        justify-content: center;
        margin: auto 0;
      }
      .product {
        max-width: 400px;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        .description {
          margin-bottom: 30px;
        }
        .image {
          max-width: 100%;
          height: 165px;
          margin-bottom: 30px;
          object-fit: contain;
          @include breakpoint($mobileLarge) {
            height: unset;
          }
        }
      }
      .mobile-app-links {
        display: flex;
        justify-content: space-around;
        a {
          width: 48%;
          max-width: 168px;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .summary {
    text-align: center;
    margin-bottom: 25px;
    .body {
      display: inline-block;
      max-width: 500px;
    }
  }

  .btn-cta {
    width: 170px;
    height: 50px;
    line-height: 50px;
    padding: unset;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #323232;
    .cta-icon {
      color: red;
      width: 24px;
      height: 24px;
    }
    @include breakpoint($mobileLarge) {
      width: 100%;
    }
  }

  &.see-all-integration {
    height: 570px;
    background-image: image-url('integrations/banner.png');
    background-repeat: no-repeat;
    background-position: center;
    .content {
      text-align: center;
      transform: translateY(35%);
      .title {
        max-width: 500px;
        font-size: 50px;
        font-weight: 800;
        letter-spacing: 0.6px;
        color: rgba(0, 0, 0, 0.85);
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
      }
      .cta {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0.36px;
        text-align: center;
        color: #27b3c1;
      }
    }

    @include breakpoint($tablet) {
      background-image: unset;
      height: 145px;
      margin: auto 50px;
      margin-top: 75px;
      .content {
        .title,
        .cta {
          font-size: 20px;
        }
      }
    }
  }
}
