@mixin Heading {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #323232;
}

@mixin SubHeading {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.48px;
  color: $brownish-grey;
}

@mixin CardHeading {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
}

@mixin BodyCopy {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  color: #7d7c7c;
  word-break: break-word;
}

@mixin CardSubheading {
  font-size: 12px;
  color: #444444;
}

@mixin WorkflowGuideHeadingActive {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.16px;
  color: #222222;
}

@mixin WorkflowGuideHeadingInactive {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  color: $very-light-pink-three;
}

@mixin ListHeadline {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  color: #222222 !important;
}

@mixin ListText {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: #a6a6a6 !important;
}

@mixin WorkflowGuideSubInactive {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
  color: $very-light-pink-three;
}

@mixin WorkflowGuideSubActive {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
  color: #717171;
}

@mixin FormValidationStyle {
  font-size: 11px;
  color: $darkish-pink;
}

@mixin HeroTitle {
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 0.28px;
  color: #323232;
}

@mixin BannerCopy {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.85);
}

@mixin BannerSubtitle {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0.36px;
  color: #403f3f;
}

@mixin CopyHeading {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.24px;
  color: #403f3f;
}
