.registration-box {
  max-width: 960px;
  margin: 0 auto;
  padding: 70px 10px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  .column {
    padding: 0 60px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .help-block {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .features {
    list-style-type: none;
    font-size: 18px;
    line-height: 38px;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    color: #403f3f;

    li {
      display: flex;
      align-items: center;
      line-height: 24px;
      margin-bottom: 10px;
    }

    a {
      color: #27b3c1;
    }
    .fas {
      color: #27b3c1;
      margin-right: 10px;
    }
  }

  .form-title {
    display: none;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  .consent {
    color: #7d7c7c;
    margin-bottom: 10px;
  }

  #suggestion {
    margin-bottom: 10px;
  }

  #btn-signup-email {
    display: none;
  }

  .split-form {
    max-width: 100%;
  }

  .btn-block {
    line-height: 50px;
    padding: 0;
    font-size: 16px;
    font-weight: 900;
  }

  #register-form {
    .btn-block.btn-action {
      background: #ffd002;
      color: #4d3500 !important;
    }
  }

  .form-signup-buttons {
    .btn.btn-block {
      position: relative;

      .ladda-label {
        position: initial;
      }

      .icon {
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 30px;
        height: 30px;
      }

      .icon-google {
        background-color: #ffffff;
        background-image: image-url('logos/google.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90%;
      }

      .icon-apple {
        font-size: 30px;
      }
    }

    .btn-apple {
      margin-top: 15px;
    }
  }

  .line-break {
    .break-line {
      background: #979797;
    }
    .break-text {
      color: #b4b4b4;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}

.valid {
  .form-control {
    border-color: #198754;
    background-image: image-url('icons/check-solid.png');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: 1rem;

    &:focus {
      border-color: #198754;
      box-shadow: 0 0 0 2px rgba(25, 135, 84, 0.25);
    }
  }
  .help-block {
    color: #198754;
  }
}
.has-error {
  .form-control {
    background-image: image-url('icons/warning.png');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: 1.2rem;
  }
}
#registration-carousel,
#simple-registration-carousel {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 980px) {
  .registration-box.box-container {
    &.two-col {
      .divider {
        display: block;
      }
      .column {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #register-form,
  #simple-registration-carousel,
  .navbar-brand {
    display: none;
  }
  .registration-box.box-container {
    display: block;
    max-width: 385px;
    margin-top: 0;
    padding: 20px 0;
    background: none;
    border: none;
    box-shadow: none;

    &.two-col {
      .divider {
        display: none;
      }
      .column {
        padding: 0;
        width: 100%;

        &.primary {
          margin: 0 auto;
          max-width: 300px;
        }
      }
    }
    .features {
      li {
        justify-content: center;
      }
    }

    #btn-signup-email {
      display: block;
    }

    &.form-email-open {
      #register-form {
        display: block;
      }
      #btn-signup-email,
      .column.secondary {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .registration-box {
    .features {
      text-align: left;
    }
  }
}

@media screen and (max-width: 480px) {
  .registration-box .column {
    padding: 0 20px;
  }
}
