.flash-message-container {
  width: 300px;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;

  .flash-message {
    margin: 5px 0;
    background: white;
    @include border-radius(6px);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;

    .flash-inner {
      padding: 8px 12px;
      border-left: 5px solid $blue;

      .flash-title {
        color: $blue;
        font-weight: bold;
        font-size: 22px;
      }

      .flash-text {
        color: #444;
        font-size: 1em;
        font-weight: bold;
      }

      &.flash-danger {
        border-left: 5px solid $red-dark;

        .flash-title {
          color: $red-dark;
        }
      }
    }
    .flash-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &-enter {
      opacity: 0.01;

      &-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
      }
    }

    &-leave {
      opacity: 1;

      &-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
      }
    }
  }
}
