$assets-path: '../../../public/assets/';

// override the default compass font-url function
// since we're not actually using compass
@function font-url($font) {
  $url: $assets-path + 'fonts/' + $font;
  @return url($url);
}

@function image-url($image) {
  $url: $assets-path + 'images/' + $image;
  @return url($url);
}
