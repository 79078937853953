@import 'fontawesome';
@import '../theme/palette';

@mixin FontAwesomeIcon($color, $icon) {
  color: $color;

  &:before {
    content: fa-content($icon);
  }
}

@mixin SvgIcon($color, $icon) {
  background: $color;
  mask: url($icon);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  &.link-icon {
    &:hover {
      background: $tealish-two;
    }
  }
}

.icon {
  @extend %fa-icon;
  @extend .fas;

  &.icon-delivery {
    @include FontAwesomeIcon($tealish-two, $fa-var-paper-plane);
  }

  &.icon-blocked {
    @include FontAwesomeIcon($brown-grey, $fa-var-paper-plane);
  }

  &.icon-open {
    @include FontAwesomeIcon($fern, $fa-var-envelope-open);
  }

  &.icon-reply {
    @include FontAwesomeIcon($robin-s-egg, $fa-var-comment-alt);
  }

  &.icon-reaction {
    @include FontAwesomeIcon($darkish-pink, $fa-var-heart);
  }

  &.icon-click {
    @include FontAwesomeIcon($golden, $fa-var-mouse-pointer);
  }

  &.icon-play {
    @include FontAwesomeIcon($tealish-three, $fa-var-play-circle);
  }

  &.icon-edit {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $brown-grey,
      '../../../public/assets/images/icons/edit.svg'
    );
  }

  &.icon-timer {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $brown-grey,
      '../../../public/assets/images/icons/timer.svg'
    );
  }

  &.icon-star {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $brown-grey,
      '../../../public/assets/images/icons/star.svg'
    );
  }

  &.icon-stars {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $sunflower-yellow,
      '../../../public/assets/images/icons/stars-2.svg'
    );
  }

  &.icon-palette {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $brown-grey,
      '../../../public/assets/images/icons/palette.svg'
    );
  }

  // sidebar icons

  &.icon-workspaces {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/campaigns.svg'
    );
  }

  &.icon-tasklist {
    width: 20px;
    height: 17px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/tasklist.svg'
    );
  }

  &.icon-replies {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/replies.svg'
    );
  }

  &.icon-results {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/results.svg'
    );
  }

  &.icon-templates {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/templates.svg'
    );
  }

  &.icon-setup {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/setup.svg'
    );
  }

  &.icon-projects {
    width: 25px;
    height: 17px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/projects.svg'
    );
  }

  &.icon-wol {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/wol.svg'
    );
  }

  &.icon-slider {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/slider.svg'
    );
  }

  &.icon-integrations {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/integrations.svg'
    );
  }

  &.icon-share-lib {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/share-lib.svg'
    );
  }

  &.icon-domains {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/settings/domain.svg'
    );
  }

  &.icon-email-domain {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/settings/email-domain.svg'
    );
  }

  &.icon-web-delivery {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/settings/web-delivery.svg'
    );
  }

  &.icon-delivery-truck {
    width: 25px;
    height: 17px;
    @include SvgIcon(
      '',
      '../../../public/assets/images/icons/settings/delivery.svg'
    );
  }

  &.icon-popup {
    width: 25px;
    height: 17px;
    @include SvgIcon(
      '',
      '../../../public/assets/images/icons/new-tab-white.svg'
    );
  }

  &.icon-socials {
    margin-top: 5px;
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/social.svg'
    );
  }

  &.icon-library {
    width: 20px;
    height: 15px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/library.svg'
    );
  }

  &.icon-screen-recording {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/screen-recording.svg'
    );
  }

  &.icon-cloud-upload {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/cloud-upload.svg'
    );

    &.icon-xl {
      width: 120px;
      height: 106px;
    }
  }

  &.icon-waving-hands {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/nav/waving-hands.svg'
    );

    &.icon-md {
      width: 30px;
      height: 30px;
    }
  }

  &.icon-hand-with-star {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/nav/hand-with-star.svg'
    );

    &.icon-md {
      width: 30px;
      height: 30px;
    }
  }

  &.icon-hands-with-heart {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/nav/hand-with-heart.svg'
    );

    &.icon-md {
      width: 30px;
      height: 30px;
    }
  }

  &.icon-double-cogs {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/nav/double-cogs.svg'
    );

    &.icon-md {
      width: 30px;
      height: 30px;
    }
  }
  &.icon-upload {
    width: 20px;
    height: 18px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/upload.svg'
    );

    &.icon-xl {
      width: 120px;
      height: 106px;
    }
  }

  &.icon-responses {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/responses.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-text {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/text.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-landing-pages {
    width: 21px;
    height: 19px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/nav/landing-pages.svg'
    );
  }

  &.icon-link {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/link.svg'
    );
  }

  &.icon-code {
    width: 20px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/code.svg'
    );
  }

  &.icon-embed {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/embed.svg'
    );
  }

  &.icon-download {
    width: 16px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/download.svg'
    );
  }

  &.icon-quote {
    @include SvgIcon(
      'unset',
      '../../../public/assets/images/icons/quote.svg'
    );
  }

  &.icon-locked {
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/locked.svg'
    );
  }

  &.icon-add-contact {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/add-contact.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-contact {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/contact.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-edit-contact {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/edit-contact.svg'
    );
    &.icon-md {
      width: 20px;
      height: 20px;
    }
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-remove-contact {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/remove-contact.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-send {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/send.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-enter-text {
    width: 25px;
    height: 25px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/enter-text.svg'
    );
    &.icon-sm {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-sort-oldest {
    width: 20px;
    height: 17px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/sort/oldest.svg'
    );
  }

  &.icon-sort-newest {
    width: 20px;
    height: 17px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/sort/newest.svg'
    );
  }

  &.icon-publish-nav-select {
    width: 30px;
    height: 30px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/select.svg'
    );
  }

  &.icon-publish-nav-template {
    width: 30px;
    height: 30px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/template.svg'
    );
  }

  &.icon-publish-nav-design {
    width: 30px;
    height: 30px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/design.svg'
    );
  }

  &.icon-publish-nav-settings {
    width: 30px;
    height: 30px;
    @include SvgIcon(
      $text-white,
      '../../../public/assets/images/icons/settings.svg'
    );
  }

  &.icon-email {
    width: 18px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/email.svg'
    );
  }

  &.icon-record {
    width: 18px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/record.svg'
    );
  }

  &.icon-bullet-list {
    width: 18px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/bullet-list.svg'
    );
  }

  &.icon-preview {
    width: 18px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/preview.svg'
    );
  }

  &.icon-redo {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/redo.svg'
    );
  }

  &.icon-message-bubble {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/message-bubble.svg'
    );
  }

  &.icon-thumbnail {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/thumbnail.svg'
    );
  }

  &.icon-optimised-sending {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/optimised-sending.svg'
    );
  }

  &.icon-scheduled {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/scheduled.svg'
    );
  }

  &.icon-error {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/error.svg'
    );
  }

  &.icon-credit-card {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/credit-card.svg'
    );
  }

  &.icon-calendar-plain {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/calendar-plain.svg'
    );
  }

  &.icon-info {
    width: 20px;
    height: 20px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/info.svg'
    );
  }

  &.icon-tick {
    width: 18px;
    height: 16px;
    @include SvgIcon(
      $text-dark,
      '../../../public/assets/images/icons/tick.svg'
    );
  }
}
