$light-teal: #99e4d8;
$aqua-marine: #32d6bc;
$tealish: #2dc2ab;
$robin-s-egg: #6ae4e8;
$tealish-two: #27b3c1;
$tealish-three: #1f96a2;
$tealish-four: #23d5c6;
$tealish-five: #daf6f7;
$parchment: #fff1b2;
$sunflower-yellow: #ffd002;
$light-yellow: #f8f4ea;
$golden: #f0c300;
$light-peach: #f9d5c1;
$bright-orange: #fc5a00;
$rust: #963b09;
$light-pink: #ffeff4;
$rosy-pink: #ec799d;
$darkish-pink: #d0577d;
$lightish-green: #6add73;
$cool-green: #2dd23b;
$fern: #52ab5a;
$white: #f8f8f8;
$very-light-pink: #f2f2f2;
$very-light-pink-two: #e6e6e6;
$very-light-pink-three: #cdcdcd;
$light-grey: #d8d8d8;
$brown-grey: #b4b4b4;
$brown-grey-two: #878787;
$brownish-grey: #666666;
$dark-grey: #403f3f;
$darker-grey: #323232;
$light-blue: #effdfe;
$camo-green: #38211d;
$beige: #f8f4ea;
