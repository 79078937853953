#inline-sign-up-form {
  margin: 110px 0;

  h1 {
    font-size: 32px;
    font-weight: 900;
  }

  .input-group {
    margin-bottom: 5px;
    padding-left: 85px;

    input {
      border: 2px solid black;

      &.btn-dark {
        background-color: black;
        color: white;
        border: 2px solid black;
      }
    }
  }
}

@media (max-width: 1024px) {
  #inline-sign-up-form {
    .input-group {
      margin: auto;
      margin-bottom: 5px;
      padding: unset;
      width: 80%;
    }
  }
}
