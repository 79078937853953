html#about {
  .navbar.navbar-default {
    height: 60px;
  }

  .row {
    padding: 0 15px;
  }

  .about-header {
    height: 180px;
    background: image-url('about/about-header.png');
    background-size: cover;
    background-position: unset;
    padding: 0;

    .about-header-text {
      display: none;
    }
  }

  .about-team,
  .about-bears,
  .about-bonjoro,
  .about-values {
    margin: 10px auto;
  }

  .team-player {
    width: 100vw;

    left: 0;
    top: 50%;
    margin-left: 0;
    margin-right: 0;

    .video-container {
      display: block;
      width: 100vw;
      height: 100vw;

      .video-hitbox {
        height: 100vw;
        width: 100vw;
        z-index: 100000;

        .video-play-box {
          display: table-cell;
          vertical-align: middle;
          position: relative;
          margin: 0 auto;
          text-align: center;
          align-items: center;
          width: 100%;
          top: 0;
          left: 0;

          .video-play-icon {
            margin: 0 auto;
          }
        }
      }
    }

    video {
      width: 100vw;
      height: 100vw;
    }
  }

  .about-bonjoro {
    .about-story-image {
      display: none;
    }
    br {
      display: none;
    }

    .container {
      padding: 0 15px;
      background-image: none;

      #about-processes {
        display: block;
        width: 100%;
        margin: 15px 0;
      }
    }
  }

  .about-team {
    .team-container {
      flex-direction: column;
      text-align: center;
      .team-member {
        max-width: none;
        .bear-suit {
          margin-bottom: 15px;

          .bear-svg {
            width: 223px;
            height: 223px;
          }
        }
      }

      .hiring {
        flex-direction: column;
        max-width: none;
        max-height: none;
        padding-top: 15px;

        .hiring-info {
          max-width: none;
          margin-top: -55px;
          padding: 0 5px;

          text-align: left;
        }
      }
    }

    .container {
      .badges {
        margin: 15px 0;
      }
      .values {
        flex-direction: column;
        .value-column {
          .value-item {
            padding: 5px;
          }
        }
      }
    }
  }
}
