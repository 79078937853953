$mobile: max-width 425px;
$mobile-small: max-width 320px;
$tablet: max-width 768px;

html#our-apps {
  body {
    background-image: image-url('our-apps/AppModal.svg');
    background-size: 125%;
    background-position: center;
    background-position-y: 45%;
    background-repeat: no-repeat;
    @include breakpoint($tablet) {
      background-image: image-url('our-apps/AppDownloadSml.svg');
      background-position-y: -7vh;
      background-position-x: 40vw;
      background-size: 40vh;
    }
  }
  .navbar-brand {
    display: none;
  }
  .container {
    margin: auto;
    transform: translate(0, -50%);
    top: 50%;
    position: absolute;
    width: 350px;
    padding: unset;
    margin-left: 20vw;
    h1 {
      font-weight: bold;
      letter-spacing: 0.14px;
      color: #323232;
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.48px;
      color: #666666;
      margin-bottom: 30px;
    }
    @include breakpoint($tablet) {
      margin: auto;
      right: 50%;
      transform: translate(50%, -50%);
    }
    @include breakpoint($mobile) {
      width: 310px;
    }
    @include breakpoint($mobile-small) {
      width: 290px;
    }
  }

  p.text-lead {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.08px;
    color: #7d7c7c;
  }
  .description {
    margin-bottom: 35px;
  }
  .mobile-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // @include breakpoint($mobile) {
    //   justify-content: center;
    // }
  }

  .url-ios img {
    object-fit: fill;
    width: 165px;
    height: 55px;
    border-radius: 10px;
  }

  .mobile-btns a {
    margin-bottom: 20px;
    img {
      @include breakpoint($mobile) {
        width: 150px;
      }
      @include breakpoint($mobile-small) {
        width: 140px;
      }
    }
  }
}
