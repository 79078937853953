footer#footer {
  background-color: #f8f4ea;
  padding-top: 64px;
  padding-bottom: 64px;
  height: unset;
  @media (min-width: 768px) {
    padding-bottom: 192px;
  }
}

.footer-container {
  --container-padding-horizontal: 28px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding-horizontal);
  padding-right: var(--container-padding-horizontal);

  @media (min-width: 640px) {
    --container-max-width: 640px;
    max-width: calc(
      var(--container-max-width) - var(--container-padding-horizontal)
    );
  }

  @media (min-width: 768px) {
    --container-max-width: 768px;
  }

  @media (min-width: 1024px) {
    --container-max-width: 1024px;
  }

  @media (min-width: 1164px) {
    --container-max-width: 1164px;
  }
}

.footer-items {
  display: grid;
  column-gap: 48px;
  row-gap: 38px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
}

.footer-item {
  display: flex;
  flex-direction: column;
  @media (min-width: 640px) {
    grid-row-start: 2;
  }

  @media (min-width: 768px) {
    grid-row-start: 1;
  }

  h4 {
    font-family: var(--fontFamily-standard);
    color: #403f3f;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 4px;
  }

  a {
    font-weight: 700;
    color: #403f3f;
    font-size: 16px;
    margin-top: 8px;
  }
}

.footer-logo {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 640px) {
    grid-row-start: 1;
    grid-column-end: 3;
    align-items: start;
    text-align: left;
  }

  a {
    font-weight: 700;
    color: #27b3c1;
    font-size: 18px;
  }

  .logo > img {
    height: 45px;
    width: auto;
  }

  p {
    font-weight: 400;
    color: #403f3f;
    font-size: 18px;
    line-height: 30px;
    max-width: 75%;
    margin-top: 16px;
  }

  .brand-container {
    height: unset;
    padding: 0;
    line-height: unset;
    .brand-bear {
      height: 45px;
      margin-right: 0;
    }
  }

  .social-media {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;

    img {
      height: 30px;
      width: auto;
      margin-right: 16px;
    }
  }
}
