.box-container {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f2f2f2;
  background-color: #ffffff;

  &.two-col {
    .divider {
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      padding: 70px 0;

      .fill {
        background: #cdcdcd;
        width: 100%;
        height: 100%;
      }
    }

    .column {
      display: flex;
      align-items: center;
      width: 50%;

      .elements {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .box-container {
    &.two-col {
      .divider {
        display: none;
      }
      .column {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
