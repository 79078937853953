.password-reset {
  .form-group {
    margin-bottom: 0;
  }
  .help-block {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .consent {
    margin-bottom: 10px;
  }
}
