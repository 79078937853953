.blog-index {
  ul.blog-posts-list {
    flex-direction: column;
    flex-wrap: wrap;

    li.post-container {
      margin: 0 0 15px 0;
      padding: 7px 7px 15px 7px;
      flex: 0 0 auto;
      float: none;
      border-bottom: 1px dotted #ddd;

      a.post-item {
        .post-published-date {
          font-size: 14px;
        }

        .post-header-image {
          height: 150px;
          width: 100%;
        }

        .post-item-content {
          padding: 0;

          h3.post-title {
            font-size: 1.3em;
          }

          .post-preview {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

.blog-topic-container {
  width: 100%;

  ul.blog-topic-list {
    flex-wrap: wrap;
  }
}

.blog-subscription-container {
  padding: 0 15px;
  form.create-subscriber-button {
    width: 100%;
  }
}

.blog-index ul.blog-posts-list li.post-container {
  border-bottom: none;
}

.blog-post-container {
  .main {
    width: 100%;

    .image {
      img {
        width: 100%;
      }
    }

    .content {
      h1.title {
        font-size: 26px;
      }

      .post-content-inner {
        p,
        ol,
        ul {
          font-size: 1.25em;
        }
      }
    }

    .similar-articles {
      ul.article-list {
        flex-direction: column;

        li {
          width: 100%;

          a {
            img {
              height: auto;
              max-height: 80px;
              width: auto;
            }
          }
        }
      }
    }
  }
}
