#home {
  .home-nav {
    ul.nav {
      li {
        margin-left: 0px;
        margin-right: 7px;
      }
    }
  }
}

.container-minimal {
  max-width: 100%;
  margin: 0;
}

.noscroll {
  overflow: hidden;
  margin-right: 0;
}
